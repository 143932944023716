import { BrowserRouter as Router, Routes, Route,Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Welcome from "./app/enrollment/components/Welcome";
import Account from "./app/enrollment/components/Account";
import PageNotFound from "./app/enrollment/components/PageNotFound";
import FeeSchedule from "./app/enrollment/components/FeeSchedule";
import EnrollmentSplash from "./app/enrollment/components/Enrollment-Splash";

declare global {
  interface Window {
      pageDataLayer: {
          content: {
              pageName: string;
              siteSectionL1: string;
              siteSectionL2: string;
              siteSectionL3: string;
              businessUnit: string;
              website: string;
          };
      };
      _satellite?: {
        track: (eventName: string) => void;
    };
  }
}
window.pageDataLayer = {
  content: {
      pageName: '',
      siteSectionL1: 'enrollment',
      siteSectionL2: '',
      siteSectionL3: '',
      businessUnit: 'optum',
      website: 'core'
  }
};

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Navigate to="/enrollment" />} />
        <Route path="/enrollment" element={<Welcome />} />
          <Route path="/enrollment/accountholder" element={<Account />} />
        <Route path="/feeschedule" element={<FeeSchedule />} />
        <Route path="/feeschedule/:param1" element={<FeeSchedule />} />
        <Route path="/feeschedule/:param1/:param2" element={<FeeSchedule />} />
        <Route path="/splash" element={<EnrollmentSplash />} />
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
