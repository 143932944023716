import React from 'react';
import Header from './Header';
import Footer from "./Footer";
// import { useAarp } from "./hooks/useAarp";
const EnrollmentSplash: React.FC = () => {
    // const { isAarp } = useAarp(); 
    return (
        <>
        <Header />
        <body >
            <div className="container-fluid update">
            <div className="col-xs-12">
            <div data-ui-view="" className="view-content">
              <section className="view-enrollment-splash">
                <div className="row">
                  <div className="col-lg-7 col-md-9 col-sm-10">
                    <div className="view-masthead">
                      <h1>Important notice regarding system availability</h1>
                    </div>
                    <p>Our systems are currently undergoing maintenance.</p>
                    <p>We apologize for any inconvenience this may cause. We appreciate your business.</p>
                    <p>
                      For general information about HSAs and Optum Bank, please visit{' '}
                      <a href="http://www.optumbank.com/" target="_blank" rel="noreferrer">
                        http://www.optumbank.com/
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
         
        <Footer />
        </div>
        </body>
        </>
    );
    
}

export default EnrollmentSplash;
