import React from "react";
import logoOptumbank from "./images/logo-optum-bank-134x23.png";
import { useAarp } from "../components/hooks/useAarp";
import aarp_optum from "./images/aarp_optum.png";

interface HeaderProgressBarProps {
    currentStage: number;
}

const HeaderProgressBar: React.FC<HeaderProgressBarProps> = ({ currentStage }) => {
    const { isAarp } = useAarp();

    return (
      //  role="navigation"
    <header className={`navbar navbar-default ${isAarp ? "page-mastheadAarp" : "page-masthead"}`}>
      <div className="page-brand navbar-header clearfix">
        <div className={`clearfix ${isAarp ? "navbar-brand-AARPH" : "navbar-brandH"}`}>
          <div className="page-brand__image-container">
            <img
              className={isAarp ? "page-brand__logo--aarp" : "page-brand__logo"}
              src={isAarp ? aarp_optum : logoOptumbank}
              alt={isAarp ? "AARP | Health Savings Account offered by Optum Financial" : "OPTUM Financial (TM)"}
            />
          </div>
          <div className="page-brand__title">
            {isAarp ? (
              <div className="page-brand__title--aarp">Enrollment</div>
            ) : (
              <>
                Health Savings Account (HSA) Enrollment
              </>
            )}
          </div>
          <div className={`${isAarp ? "page-brand__progress-barAarp" : "page-brand__progress-bar"}`}>
            <div className={ `${
                            currentStage === 1 ? "activeNav" : ""
                         } ${currentStage === 5 ? "thankyoudisplay" : ""} 
                         ${isAarp ? "nav-stepsAARP" : "nav-steps"}`}
                         aria-current={currentStage === 1 ? 'step' : undefined}>
              <p>Step 1</p>
            </div>
            <div className={ `${
                            currentStage === 2 ? "activeNav" : ""
                         } ${currentStage === 5 ? "thankyoudisplay" : ""} 
                         ${isAarp ? "nav-stepsAARP" : "nav-steps"}`}
                         aria-current={currentStage === 2 ? 'step' : undefined}>
              <p>Step 2</p>
            </div>
            <div 
            className={ `${
                            currentStage === 3 ? "activeNav" : ""
                         } ${currentStage === 5 ? "thankyoudisplay" : ""} 
                         ${isAarp ? "nav-stepsAARP" : "nav-steps"}`}
                         aria-current={currentStage === 3 ? 'step' : undefined}>
              <p>Step 3</p>
            </div>
            <div className={ `${
                            currentStage === 4 ? "activeNav" : ""
                         } ${currentStage === 5 ? "thankyoudisplay" : ""}
                         ${isAarp ? "nav-stepsAARP" : "nav-steps"}`}
                         aria-current={currentStage === 4 ? 'step' : undefined}>
              <p>Step 4</p>
            </div>
          </div>
        </div>
      </div>
    </header>
    );
};

export default HeaderProgressBar;