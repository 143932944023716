import React, {useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.css";
import {SubmitCallback} from "./Account";
import EnrollmentModal from "./modal/cancelEnrollment";
import { useAarp } from "../components/hooks/useAarp";
import {AccountData} from "./modal/enrollmentData";
import { publishPostPageData } from "../services/analyticsService";

const Verify: React.FC<{accountData:AccountData | undefined, onSubmit: SubmitCallback, currentStage: number, setCurrentStage: React.Dispatch<React.SetStateAction<number>>  }> = ({accountData, onSubmit, currentStage, setCurrentStage }) => {
  const [showModal, setShowModal] = useState(false);
  const [maskedSsn, setMaskedSsn] = useState<string>("");

  useEffect(() => {
    window.pageDataLayer.content.pageName="verify";
    publishPostPageData(window.pageDataLayer.content, "hsastep3")
 }, []);

// eslint-disable-next-line react-hooks/exhaustive-deps 
  useEffect(() => {
    const ssn = accountData!.ssn;
    if (!ssn || ssn.startsWith("XXX-XX-")) {
      setMaskedSsn(ssn);
      return;
    }
    
    const maskedValue = "XXX-XX-" + ssn.slice(-4);
    setMaskedSsn(maskedValue);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const { isAarp } = useAarp();
  const account = accountData!;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0'); // Month is zero-based
    let day = date.getDate().toString().padStart(2, '0');

    return `${month}-${day}-${year}`;
  };

  const handleNextClick = () => {
    // Handle going back
  onSubmit("Stage 3");
  setCurrentStage(4);
  };
  const handleBackClick = () => {
  onSubmit("Stage 3 back");
  setCurrentStage(2);
  };

  const handleCancel = () => {
    // Handle cancellation
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <>
    <main role="main">
 <section className="view-verify">
 <div className="view-masthead">
   <h1>
     <span className="hidden-xs">Step 3: </span>Verify
   </h1>
 </div>

 <h2>
   Personal Information <small>(Account holder)</small>
 </h2>
 <table className="table-condensed table-verify">
   <tbody>
     <tr>
       <th>First name</th>
       <td>{account.firstName}</td>
     </tr>
     <tr>
       <th>Middle initial</th>
       <td>{account.middleInitial}</td>
     </tr>
     <tr>
       <th>Last name</th>
       <td>{account.lastName}</td>
     </tr>
     <tr>
       <th>Social security number or tax ID</th>
       <td>{maskedSsn}</td>
     </tr>
     <tr>
       <th>Date of birth</th>
       <td>{account.dob}</td>
     </tr>
     <tr>
       <th>Home phone</th>
       <td>{account.homePhone}</td>
     </tr>
     <tr>
       <th>Work phone</th>
       <td>{account.workPhone}</td>
     </tr>
     <tr>
       <th>Email address</th>
       <td>{account.email}</td>
     </tr>
     <tr>
       <th>Verification code</th>
       <td>{account.verificationCode}</td>
     </tr>
   </tbody>
 </table>

 <h2>Home Address</h2>
 <table className="table-condensed table-verify">
   <tbody>
     <tr>
       <th>Home address</th>
       <td>{account.homeAddress.line1}</td>
     </tr>
     <tr>
       <th>City</th>
       <td>{account.homeAddress.city}</td>
     </tr>
     <tr>
       <th>State</th>
       <td>{account.homeAddress.state}</td>
     </tr>
     <tr>
       <th>Zip code</th>
       <td>
         <span>{account.homeAddress.zip}</span>
         <span>{account.homeAddress.zipplus4 ? ` - ${account.homeAddress.zipplus4}` : ""}</span>
       </td>
     </tr>
   </tbody>
 </table>

 {account.useAltMailingAddress && (
   <div>
     <h2>Mailing Address</h2>
     <table className="table-condensed table-verify">
       <tbody>
         <tr>
           <th>Mailing address</th>
           <td>{account.mailingAddress.line1}</td>
         </tr>
         <tr>
           <th>City</th>
           <td>{account.mailingAddress.city}</td>
         </tr>
         <tr>
           <th>State</th>
           <td>{account.mailingAddress.state}</td>
         </tr>
         <tr>
           <th>Zip code</th>
           <td>
             <span>{account.mailingAddress.zip}</span>
             <span>
               {account.mailingAddress.zipplus4
                 ? ` - ${account.mailingAddress.zipplus4}`
                 : ""}
             </span>
           </td>
         </tr>
       </tbody>
     </table>
   </div>
 )}

 <h2>Medical Information - High Deductible Health Plan (HDHP)</h2>
 <table className="table-condensed table-verify">
   <tbody>
     <tr>
       <th>Enrolling through employer  or< br /> financial advisor</th>
       <td>{account.individualEnrollment ? "Yes" : "No"}</td>
     </tr>
     {account.group.id && (
       <tr>
         <th>Group number or financial < br /> advisor number</th>
         <td>{account.group.id}</td>
       </tr>
     )}
     {account.group.name && (
       <tr>
         <th>Employer name</th>
         <td>{account.group.name}</td>
       </tr>
     )}
     <tr>
       <th>Who is covered</th>
       <td>{account.group.coverage === "I" ? "Individual" : "Family"}</td>
     </tr>
     {account.group.effectiveDate && (
       <tr>
         <th>HDHP effective date</th>
         <td>{formatDate(account.group.effectiveDate)}</td>
       </tr>
     )}
   </tbody>
 </table>

 {account.requestAdditionalCard && (
   <div>
     <h2>Additional Cardholder</h2>
     <table className="table-condensed table-verify">
       <tbody>
         <tr>
           <th>First name</th>
           <td>{account.additionalCard.firstName}</td>
         </tr>
         <tr>
           <th>Middle initial</th>
           <td>{account.additionalCard.middleInitial}</td>
         </tr>
         <tr>
           <th>Last name</th>
           <td>{account.additionalCard.lastName}</td>
         </tr>
       </tbody>
     </table>
   </div>
 )}

      <div className="form-group">
        <div className="block-actions control-input-group">
          <button
            type="button"
            id="js-button__previous"
            className="btn btn-default"
            onClick={handleBackClick}
          >
            Previous
          </button>
          <button
            type="button"
            id="js-button__next"
            className="btn btn-primary"
            onClick={handleNextClick}
          >
            Next
          </button>
          {isAarp && (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCancel}
              >
                Cancel Enrollment
              </button>
              <EnrollmentModal show={showModal} onClose={handleCloseModal} />
            </>
          )}
        </div>
      </div>
      
    </section>
    </main>
    </>
  );
};

export default Verify;
