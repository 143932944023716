import { useEffect, useState, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

const timeout = 420_000
const promptBeforeIdle = 120_000

export default function SessTimeout() {
  // eslint-disable-next-line
  const [, setState] = useState<string>('Active') // state ommitted as it was declared but its value is never read. -> omitted to resolve lint warning
  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const contButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open && contButtonRef.current) {
      contButtonRef.current.focus();
    }
  }, [open]);

  useEffect(() => {

  const handleTabKey = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      const focusableElements = [contButtonRef.current];
      const lastFocusable = focusableElements[focusableElements.length - 1];
      const firstFocusable = focusableElements[0];

      if(!e.shiftKey && document.activeElement === lastFocusable) {
        firstFocusable?.focus();
          e.preventDefault();
      } else if(e.shiftKey && document.activeElement === firstFocusable) {
        lastFocusable?.focus();
        e.preventDefault();
      }
    }
  };

  document.addEventListener('keydown', handleTabKey);

  return () => {
    document.removeEventListener('keydown', handleTabKey);
  }
}, []);

  const onIdle = () => {
    setState('Idle')
    setOpen(false)
    navigate('/enrollment')
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}`;
    const formattedSeconds = `${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;

    if(minutes > 0){
        return `${formattedMinutes}:${formattedSeconds} minutes`;
    }

    return `${formattedSeconds} seconds`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }
  
  return (
    <>

      <div className='modal bgdisplay' style={{ display: open ? 'flex' : 'none' }}>
      <div className="modal-dialog" >
            <div className="modal-content" aria-modal="true">
                <div className="modal-header">
                    <h2 className="modal-title">Action Needed</h2>
                </div>
                <div className="modal-body">
                Your session will expire in <strong>{formatTime(remaining)}</strong>.
                Click continue to prevent your entered information from being lost.
                </div>
                <div className="modal-footer">
                    <button ref={contButtonRef} className="btn btn-primary" onClick={handleStillHere}>
                    Continue
                    </button>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
