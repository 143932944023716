import React from "react";
import { useAarp } from "../components/hooks/useAarp";
import logoOptumbank from "./images/logo-optum-bank-134x23.png";
import aarp_optum from "./images/aarp_optum.png";

function Header() {
  const { isAarp } = useAarp();
  return (
    <header className={`navbar navbar-default ${isAarp ? "page-mastheadAarpH" : "page-mastheadH"}`} >
      <div className={` navbar-header clearfix ${isAarp ? "page-brand-AARP" : "page-brand"}`}>
        <div className={`clearfix ${isAarp ? "navbar-brand-AARP" : "navbar-brand"}`}>
          <div className="page-brand__image-container">
            <img
              className={isAarp ? "page-brand__logo--aarp" : "page-brand__logo"}
              src={isAarp ? aarp_optum : logoOptumbank}
              alt="OPTUM Financial (TM)"
            />
          </div>
          <div className="page-brand__title">
            {isAarp ? (
              <div className="page-brand__title--aarp">Enrollment</div>
            ) : (
              <>
                Health Savings Account (HSA) Enrollment
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
