import React from "react";
import { useAarp } from "../components/hooks/useAarp";

function NeedHelp() {
  const { isAarp } = useAarp();
  return (
    <section className="row">
      <div className="col-xs-12">
        <div>
          {isAarp ? (
            <>
              <h2>Need Help?</h2>
              <p>Call Optum Bank 24/7 at 1-844-458-6738</p>
              <p>
                <a href="https://myaarphsa.com/" target="_blank" rel="noreferrer">
                  myaarphsa.com
                </a>
              </p>
              <p>
                Optum Bank offers AARP Health Savings Account offered by Optum
                Bank to eligible AARP members.
              </p>
              <p>
                Optum Bank pays royalty fees to AARP for the use of its
                intellectual property. These fees are used for the general
                purposes of AARP. Provider offers are subject to change and may
                have restrictions. Please contact the provider directly for
                details.
              </p>
              <p>
                *Cardholder must use their valid Optum Bank debit Mastercard® at
                a participating Walgreens store in order to qualify for the 3%
                discount on eligible items or services. The 3% discount does not
                apply to purchases made on Walgreens.com. The 3% discount will
                be calculated after the Optum Bank debit Mastercard® has been
                identified as the method of payment at the register and will not
                be applied to the following: alcohol; dairy; tobacco; stamps;
                phone/prepaid/gift cards; money orders/transfers; transportation
                passes; lottery tickets; charitable donations; pseudoephedrine
                or ephedrine products; clinic services; items or services
                provided by a Pharmacist; prescriptions; sales tax; shipping;
                membership fees for any prescription savings programs; discount
                club memberships; and any other items specified as exclusions by
                Walgreen Co. from time to time or where prohibited by law. The
                3% discount cannot be combined with Walgreens coupons that apply
                a dollar amount off the Cardholder’s total purchase, and other
                promotions, offers or coupons as specified by Walgreens from
                time to time or where prohibited by law.
              </p>
              <p>
                Health savings accounts (HSAs) are individual accounts offered
                or administered by Optum Bank®, Member FDIC, and are subject to
                eligibility requirements and restrictions on deposits and
                withdrawals to avoid IRS penalties. State taxes may apply. Fees
                may reduce earnings on account. The content on this website is
                not intended as legal or tax advice. Federal and state laws and
                regulations are subject to change.
              </p>
              <p>
                Mutual fund investment options are made available through the
                services of an independent investment advisor. Shares are
                offered through Charles Schwab & Co., Inc., a registered
                broker-dealer. Orders are accepted to effect transactions in
                securities only as an accommodation to HSA owners. Optum Bank is
                not a broker-dealer or registered investment advisor, and does
                not provide investment advice or research concerning securities,
                make recommendations concerning securities, or otherwise solicit
                securities transactions.
              </p>
            </>
          ) : (
            <>
              <h2>Need Help?</h2>
              <div>Call us at 1-866-234-8913</div>
              <div>
                24 hours a day, 7 days a week, excluding major U.S. holidays
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default NeedHelp;
