import  { useEffect } from "react";
import { useAarp } from "../components/hooks/useAarp";

function MainContent({ handleNextClick }: any) {
  const { isAarp } = useAarp();
  useEffect(() => {
    sessionStorage.removeItem("savEtEffectiveDateValue");
  }, []);
  return (
    <main className="row">
      <div className="col-xs-12">
        <div className="ofs-alerts" ng-show="alerts"></div>
        <div className="view-content ng-scope" data-ui-view="">
          <section
            className="view-welcome row ng-scope"
            ng-controller="WelcomeController as wCtrl"
          >
            <div
              ng-if="!wCtrl.aarp()"
              className="col-sm-10 col-md-8 col-lg-6 ng-scope"
            >
              <div className="view-masthead">
                {isAarp ? (
                  <h1>
                    Welcome to the AARP<sup>®</sup> Health Savings Account
                    offered by Optum Bank<sup>®</sup> online enrollment site
                  </h1>
                ) : (
                  <h1>Welcome to the Optum Bank HSA Online Enrollment site</h1>
                )}
              </div>
              {isAarp ? (
                <>
                  <h2>
                    Need a smart, simple and secure way to pay for medical costs
                    now and through retirement?
                  </h2>
                  <p>
                    The AARP® Health Savings Account offered by Optum Bank
                    provides you a tax advantaged way to save and pay for health
                    care now and through retirement. Plus you get access to
                    unique planning tools and resources along with exclusive
                    discounts.
                  </p>

                  <h2>Best-in-class health savings account (HSA) solution</h2>
                  <p>
                    Get the HSA that brings together tax advantages with
                    exclusive resources for AARP members. The money you deposit
                    to your HSA is federal income tax-advantaged and any
                    interest you earn is also tax-advantaged. Generally, savings
                    grow income tax-free and withdrawals for qualified medical
                    expenses are also income tax-free.
                  </p>

                  <h2>Rewards</h2>
                  <p>
                    Use your Optum Bank debit Mastercard® at Walgreens to earn a
                    3% discount on eligible non-prescription purchases — plus
                    Balance Rewards points.*
                  </p>

                  <h2>Low or fewer fees</h2>
                  <p>
                    Get a 20% discount off monthly maintenance fees or waived
                    monthly fees for account holders 65+.
                  </p>

                  <h2>Trusted experts</h2>
                  <p>
                    Simplify your path to retirement with help from the HSA
                    experts at Optum Bank through this AARP partnership.
                  </p>

                  <h2>Simplified payments</h2>
                  <p>
                    By loading prepaid funds or linking your personal bank
                    account, your card will leverage Optum Wallet<sup>®</sup>{" "}
                    technology. This ensures that you are covered for both
                    qualified medical purchases, such as prescriptions and
                    doctor visits, and non-qualified medical purchases, such as
                    gas and coffee. Your Optum Bank debit Mastercard will
                    determine the appropriate source of payment and
                    automatically deduct funds from the right place.
                  </p>
                </>
              ) : (
                <>
                  {" "}
                  <p>
                    Thank you for choosing to open your Health Savings Account
                    (HSA) with Optum Bank.
                  </p>
                  <p>
                    You are about to complete an application for a Health
                    Savings Account. By continuing, you understand that you will
                    be entering into a legally binding agreement with Optum
                    Bank. You will have the ability to cancel your application
                    at any time before the final screen.
                  </p>
                  <p>
                    The online enrollment process takes approximately 10 minutes
                    to complete. Once you begin the enrollment process, you must
                    complete it before you log off. If you log off before you
                    complete the enrollment process, none of your information
                    will be saved.
                  </p>
                </>
              )}

              <h2>Qualifying for an HSA</h2>
              {isAarp ? (
                <p>
                  The AARP<sup>®</sup> Health Savings Account offered by Optum
                  Bank<sup>®</sup> is FDIC insured and can be used to pay for
                  qualified health expenses for yourself and your covered
                  dependents tax free. In addition to being a current AARP
                  member, to be an eligible individual and qualify for an HSA,
                  you must meet the following requirements as defined by the
                  IRS:
                </p>
              ) : (
                <p>
                  Optum Bank HSAs are FDIC insured bank accounts that you can
                  use to pay for qualified health expenses for yourself and your
                  covered dependents tax free. To be an eligible individual and
                  qualify for an HSA, you must meet the following requirements,
                  as defined by the IRS:
                </p>
              )}
              <ul>
                <li>
                  You must be covered under a high deductible health plan (HDHP)
                  on the first day of the month.
                </li>
                <li>
                  You have no other health coverage except what is permitted by
                  the IRS.
                </li>
                <li>You are not enrolled in Medicare.</li>
                <li>
                  You cannot be claimed as a dependent on someone else's tax
                  return.
                </li>
              </ul>
              <p>See IRS Publication 969 for more information.</p>
              <p>
                If you do not meet the HSA eligibility requirements noted above
                but you previously qualified for an HSA, you are able to
                transfer your existing HSA assets from your current
                administrator to Optum Bank.&nbsp;Complete your application and
                once your account is set up with Optum Bank, you can transfer
                your existing HSA assets by completing the HSA Rollover Transfer
                Request Form and submitting it to Optum Bank. This form can be
                found online after you log into your Optum Bank HSA, under Help
                &amp; Tools &gt; Forms &amp; Documents &gt; HSA Rollover
                Transfer Request Form.
              </p>
              <h2>Before you get started</h2>
              <p>
                Please{" "}
                <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">
                  download Adobe Reader
                </a>{" "}
                if it is not installed on your machine. This will enable you to
                read the terms &amp; conditions.
              </p>
              <p>
                To complete the enrollment process, please have the following
                information handy:
              </p>
              <div className="ng-scope">
                <ul>
                  <li>Your Social Security Number</li>
                  <li>A valid e-mail address</li>
                  <li>
                    Your Medical ID card containing your Group/Employer #
                    (unless you are not enrolling as part of an employer group)
                  </li>
                </ul>
                In addition, you may need to upload copies of the following in
                order to verify your identity:
                <ul>
                  <li>A copy of your Social Security card.</li>
                  <li>
                    A copy of a valid photo identification card with your
                    address. (A driver's license, passport, state- or
                    government-issued photo ID will do.)
                  </li>
                  <li>
                    <u>If your photo ID does not have your address</u>, please
                    send a copy of a utility bill with your name and physical
                    address listed, such as an electricity bill, gas bill,
                    renter’s agreement or mortgage statement (a cell phone bill
                    will not work).
                  </li>
                </ul>
              </div>
            </div>

            <div className="block-actions">
              <button
                type="button"
                id="js-button__next"
                className="btn btn-primary"
                onClick={handleNextClick}
              >
                Next
              </button>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default MainContent;
