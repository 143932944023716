import {AnalyticsContent} from "../components/modal/enrollmentData";


// Define function for sending analytics data
function publishPostPageData(content: AnalyticsContent, track: string | undefined): void {

    if (typeof window === 'undefined') {
        console.error('Cannot access window object. Skipping analytics tracking.');
        return;
    }

    // guard against missing data
    if (!window.pageDataLayer) {
        console.error('Page layer not initialized. Initialize page data layer in the layout file.');
        return;
    }

    // update the page data layer with new values
    for (const attrname in content) {
        if (content.hasOwnProperty(attrname)) {
            window.pageDataLayer.content = window.pageDataLayer.content || {};
        }
    }

    // guard against broken Adobe analytics
    if (!window._satellite) {
        console.error('Adobe analytics not ready or script not installed.');
        return;
    }

    // trigger the view track event
    window._satellite.track(track ? track : 'trackPageView');
}

export { publishPostPageData };