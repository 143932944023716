import { useEffect, useState } from "react";

export const useGroup = () => {
  const [isGroup, setIsGroup] = useState(false);

  useEffect(() => {
    setIsGroup(window.location.toString().includes("group="));
  }, []);

  return { isGroup };
};