import React from "react";
import logocomodosecure from "./images/logo-comodo-secure.png";
import logomemberFDIC from "./images/logo-member-FDIC.png";
import NeedHelp from "./NeedHelp";

function openComodoVerify() {
  var url =
    "https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC4&v_search=enrollhsa.optumbank.com&x=6&y=5";
  window.open(url, "Comodo Secure", "width=443,height=550");
  return false;
}

function Footer() {
  return (
    <>
      <NeedHelp />
      <footer className="page-footer">
        <div className="footer-copyright">
          © {new Date().getFullYear()} Optum Bank. All Rights Reserved.
        </div>
        <ul className="footer-links">
          <li>
            <a
              href="https://www.optum.com/en/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy & Security
            </a>
          </li>
          <li>
            <a
              href="https://www.optum.com/en/terms-of-use.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </li>
        </ul>
        <ul className="footer-certifications">
          <li className="fdic">
            <img src={logomemberFDIC} alt="Member FDIC" />
          </li>
          <li className="comodo">
            {/* eslint-disable-next-line */}
            <a onClick={openComodoVerify}>
              <img alt="Comodo Secure" src={logocomodosecure} />
            </a>
            {/* disabled since href is not being used (causing lint warning) but the navigation is handled by the onClick function */}
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Footer;
