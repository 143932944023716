import moment, { MomentInput } from 'moment';

interface OfsDateParser {
    // Define the structure of the OfsDateParser interface
    age(value: string): number;
    format(value: string, formatString?: string): string;
    formatDob(value: string): string;
    formatIso(value: string): string;
    isAdultDob(value: string): boolean;
    isValidDate(value: string): boolean;
    parse(value: string): MomentInput | null;
}

const ofsDateParser: OfsDateParser = {
    age(value: string): number {
        const date = this.parse(value);
        return moment().diff(date, 'years');
    },

    format(value: string, formatString: string = 'MM-DD-YYYY'): string {
        const date = this.parse(value);
        return date ? moment(date).format(formatString) : '';
    },

    formatDob(value: string): string {
        const date = this.parse(value);
        return date ? moment(date).format('MMMM D, YYYY') : '';
    },

    formatIso(value: string): string {
        const date = this.parse(value);
        return date ? moment(date).format('YYYY-MM-DD') : '';
    },

    isAdultDob(value: string): boolean {
        return this.age(value) >= 18;
    },

    isValidDate(value: string): boolean {
        return !!this.parse(value);
    },

    parse(value: string): MomentInput | null {
        const date = Object.prototype.toString.call(value) === '[object Date]' ?
            moment(value) :
            moment(value, ['M-D-YYYY', 'M/D/YYYY','MM-DD-YYYY', 'MM/DD/YYYY'], true);

        return date.isValid() ? date : null;
    }
};

export default ofsDateParser;
