import React from 'react';
import Header from './Header';
import Footer from "./Footer";
import { useAarp } from "../components/hooks/useAarp";
const PageNotFound: React.FC = () => {
    const { isAarp } = useAarp();
    return (
        <>
        <Header />
        {/* className={`site-enrollment brand-${theme.key}`} */}
        <body >
            <div className="container-fluid update">
                <main className="row">
                    <div className="col-xs-12">
                        <div className="view-masthead">
                            <h1>Page not found</h1>
                        </div>
                        <p>
                            The URL you requested is not available. Check the address, or report an error: {isAarp ? '1-844-458-6738' : '1-866-234-8913'}.
                        </p>
                    </div>
                    
                </main>
            <Footer />
            </div>
        </body>
        </>
    );
    
}

export default PageNotFound;
