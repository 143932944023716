import { useEffect, useState } from "react";

export const useAarp = () => {
  const [isAarp, setIsAarp] = useState(false);

  useEffect(() => {
    setIsAarp(window.location.toString().includes("aarp"));
  }, []);

  return { isAarp };
};
