import React, { createContext, useContext, useState, ReactNode } from "react";

interface HeaderContextType {
    currentStage: number;
    setCurrentStage: React.Dispatch<React.SetStateAction<number>>;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const HeaderProvider: React.FC<{children: ReactNode }> = ({ children }) => {
    const [currentStage, setCurrentStage] = useState(1);

    return (
        <HeaderContext.Provider value={{ currentStage, setCurrentStage }}>
            {children}
        </HeaderContext.Provider>
    );
};

export const useHeader = () => {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error("useHeader must be used within a HeaderProvider");
    }
    return context;
};