import React, { useEffect, useRef } from "react";

interface FormDownProps {
  onClose: () => void;
}

const FormDown: React.FC<FormDownProps> = ({ onClose }) => {
  const okButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    if(okButtonRef.current){
      okButtonRef.current?.focus();
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        okButtonRef.current?.focus();

        if (e.shiftKey) {
          e.preventDefault();
          okButtonRef.current?.focus();
        }
      }

      if(e.key ==='ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const redirectToHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="modal show">
      <div className="modal-dialog" >
        <div className="modal-content" aria-modal="true">
          <div className="modal-header">
            <h4>We Apologize</h4>
          </div>
          <div className="modal-body">
            Our enrollment application is currently unavailable, please try at a
            later time.
          </div>
          <div className="modal-footer left-align-modal">
              <button ref={okButtonRef} onClick={redirectToHome} type="button" className="btn btn-primary formdownbutton ">
                OK
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDown;