import { ColorRing } from "react-loader-spinner";
import React from "react";

interface LoaderProps {
  wrapperStyle: { height: string; width: string };
  colors: Array<string>;
}

const Loader: React.FC<LoaderProps> = ({ wrapperStyle, colors }) => {
  return (
    <ColorRing
      visible={true}
      ariaLabel="color-ring-loading"
      wrapperStyle={wrapperStyle}
      wrapperClass="color-ring-wrapper"
      colors={[
        "#4a173f75",
        "#4a173f75",
        "#4a173f75",
        "#4a173f75",
        "#4a173f75",
      ]}
    />
  );
};

export default Loader;
