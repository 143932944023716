import React from 'react';

export interface AlertProps {
  type: string;
  title: string;
  msg: string;
  index: number;
  closeAlert: (index: number) => void;
}

export const Alert: React.FC<AlertProps> = ({ type, title, msg, index, closeAlert }) => (
  <div className={`alert alert-${type}`} role="alert" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
    <div>
      <strong>{title}</strong> {msg}
    </div>
    <button
      type="button"
      className="close"
      onClick={() => closeAlert(index)}
      style={{ margin: '0', padding: '4px', alignSelf: 'flex-start' }}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
);

interface AlertsProps {
  alerts: AlertProps[];
  closeAlert: (index: number) => void;
}

const Alerts: React.FC<AlertsProps> = ({ alerts, closeAlert }) => (
  <>
    {alerts.map((alert, index) => (
      <Alert key={index} {...alert} index={index} closeAlert={closeAlert} />
    ))}
  </>
);

export default Alerts;
