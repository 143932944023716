// Loader.tsx

import React from 'react';

const LoaderTemp: React.FC<{ title: string, message: string }> = ({ title, message }) => {
    return (
        <div className="modal-body modal-loading">
            <div className="loading-ring">
                <div className="animation--border">
                    <div className="loading-fc1 loading-fc"></div>
                    <div className="loading-fc2 loading-fc"></div>
                    <div className="loading-fc3 loading-fc"></div>
                    <div className="loading-fc4 loading-fc"></div>
                    <div className="loading-fc5 loading-fc"></div>
                    <div className="loading-fc6 loading-fc"></div>
                    <div className="loading-fc7 loading-fc"></div>
                    <div className="loading-fc8 loading-fc"></div>
                </div>
            </div>
            <h2>{title}</h2>
            <p>{message}</p>
        </div>
    );
};

export default LoaderTemp;