import React, { useEffect, useRef } from "react";

interface validateAarpModalPprops {
  onClose: () => void;
  isValidAarp: boolean;
}

const ValidateAarpModal: React.FC<validateAarpModalPprops> = ({ onClose, isValidAarp }) => {
  const contButtonRef = useRef<HTMLButtonElement>(null);
  const cancButtonRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const linkkRef = useRef<HTMLAnchorElement>(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    //document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    if (!isValidAarp) {
      // Invalid AARP case
      if (linkRef.current) {
        linkRef.current.focus();
      }

      const handleTabKey = (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
          const focusableElements = [linkRef.current, linkkRef.current, contButtonRef.current, cancButtonRef.current];
          const lastFocusable = focusableElements[focusableElements.length - 1];
          const firstFocusable = focusableElements[0];

          if (!e.shiftKey && document.activeElement === lastFocusable) {
            firstFocusable?.focus();
            e.preventDefault();
          } else if (e.shiftKey && document.activeElement === firstFocusable) {
            lastFocusable?.focus();
            e.preventDefault();
          }
        }

        if(e.key ==='ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          e.preventDefault();
        }
      };

      document.addEventListener('keydown', handleTabKey);

      return () => {
        document.removeEventListener('keydown', handleTabKey);
      };
    } else {
      // Valid AARP case
      if (contButtonRef.current) {
        contButtonRef.current.focus();
      }

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
          e.preventDefault();
          contButtonRef.current?.focus();

          if (e.shiftKey) {
            e.preventDefault();
            contButtonRef.current?.focus();
          }
        }

        if(e.key ==='ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          e.preventDefault();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isValidAarp]);

  const redirectToHome = () => {
    window.location.href = '/';
  };


  return (
    <div className="modal show" >
      <div className="modal-dialog" >
        <div className="modal-content" role='dialog' aria-modal="true">
          <div className="modal-header">
            <h2 className="modal-title">
              {isValidAarp
                ? "Your AARP membership has been verified"
                : "We were unable to verify an active AARP membership"}
            </h2>
          </div>
          <div className="modal-body">
            {isValidAarp ? (
              <p> 
                {/* true */}
                Select continue to set up your AARP® Health Savings Account
                offered by Optum Bank®.
              </p>
            ) : (
              <>
                <p>
                  {/* false */}
                  In order to qualify for the AARP<sup>®</sup> Health Savings
                  Account offered by Optum Bank<sup>®</sup>, you must have a
                  current membership with AARP. If you feel you received this
                  message in error, please contact AARP customer support at
                  1-888-687-2277.
                </p>
                <p>
                  If you would like to enroll in AARP,{" "}
                  <a ref={linkRef} className="aarpLink" href="https://appsec.aarp.org/mem/join?campaignid=FBBUMAWS&cmp=ASI_P_MU_JN_AWS">
                    click here
                  </a>
                  .
                </p>
                <p>
                  If you would like to renew your AARP membership,{" "}
                  <a ref={linkkRef} className="aarpLink" href="https://appsec.aarp.org/mem/renew?campaignid=FBBUMAWS&cmp=ASI_P_MU_RN_AWS">
                    click here
                  </a>
                  .
                </p>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary enrollbutton"
              onClick={onClose}
              ref={contButtonRef}
            >
              Continue
            </button>
            {/* org = !isValidAarp */}
            {!isValidAarp && (
                <button
                  onClick={redirectToHome}
                  type="button"
                  className="btn btn-primary invalidAARPbutton "
                  ref={cancButtonRef}
                >
                  Cancel
                </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateAarpModal;