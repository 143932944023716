import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import OfsInfoTooltip from '../../validators/OfsInfoTooltip';
import moment from 'moment';
import ofsDateParser from '../../utility/ofsDateParser';

interface CustomCalenderProps  {
  isFormSubmit: boolean;
  setEffectiveDateValue?: any;
  effectiveDateValue?: Date | null;
}

const CustomCalender : React.FC<CustomCalenderProps> = ({isFormSubmit, setEffectiveDateValue, effectiveDateValue}) => {
  
  const [selectedDate, setSelectedDate] = useState<Date | null>(effectiveDateValue ? new Date(effectiveDateValue) : null);
  const [isOnChange, setIsOnChange] = useState<string>('');
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const DatePickerRef = useRef<any>(null);
  const [calenderOpen, setCalenderOpen ] = useState<boolean>(false)  ;

  const handleDateChange = (date: Date | null) => {

    if(date==null){
      return;
    }
   // Check if the date is selected and does not match the required format
   
   if (date && ofsDateParser.isValidDate(date?.toLocaleDateString('en-US')) ) {
    setIsDateValid(true);
    setSelectedDate(date);
    setEffectiveDateValue(date);
    setIsOnChange('focused');
    setCalenderOpen(false);
    date && window.sessionStorage.setItem("savEtEffectiveDateValue", moment(date, "MM-DD-YYYY").format("MM-DD-YYYY").toString());    
  }
  else{
    setIsDateValid(false);

  }
  
   };    
  
   const handleBlur = () => {
    if(selectedDate==null){
      return;
    }
    setIsOnChange('blur');
    if (selectedDate && ofsDateParser.isValidDate(selectedDate?.toLocaleDateString('en-US'))) {
      setIsDateValid(true);
    }
    else
    {
      setIsDateValid(false);
    }

  };

  const handleTodayButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSelectedDate(new Date());
    setEffectiveDateValue(new Date());
    setCalenderOpen(false);
    window.sessionStorage.setItem("savEtEffectiveDateValue", moment(new Date(), "MM-DD-YYYY").format("MM-DD-YYYY").toString());
  };

  const handleClearButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSelectedDate(null);
  };

  const handleCloseButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if(DatePickerRef.current) {
      DatePickerRef.current.setOpen(false);
      setCalenderOpen(false);
    }
    
  };

  const handleCalendarOpen = () => {    
    if (DatePickerRef.current) {       
    DatePickerRef.current.setFocus();  
    }  
};

  const handleOpenButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if(DatePickerRef.current) {
      const shouldOpen = !calenderOpen;
      DatePickerRef.current.setOpen(shouldOpen);
      setCalenderOpen(shouldOpen);
      if(shouldOpen){

        setTimeout(() => {
          const elements = document.querySelectorAll<HTMLElement>('.react-datepicker__day--today:not(.react-datepicker__day--disabled)');
          
          if(elements.length) {
            elements[0].focus();
          }
        }, 10);
      }
    }
  };

const handleKeyDown = (e: React.KeyboardEvent<any>) => {
      const key = e.key;

  if(e.target instanceof HTMLInputElement) {
        // Allow backspace, delete, and arrow keys
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab') {
      return;
    }
  
    // Prevent typing if the maximum length is reached
    if (e.currentTarget.value.length >= 10) {
      e.preventDefault();
    }
  
    // Allow only numbers and hyphen
    if (key.length === 1 && !/^[0-9-]$/.test(key)) {
      e.preventDefault();
    }

  }
  };

  const CustomHeader = ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled,
    prevYearButtonDisabled, nextYearButtonDisabled, increaseYear, decreaseYear}: any) => {
    return (
      <div className="customHeader" style={{ display: "flex", flexDirection: 'column', alignItems: 'center'}}>
        <div className='calendar-header-text'>
          {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <div style={{ margin: 3, display: "flex", justifyContent: 'space-between', width: '100%' , fontSize:'1rem',fontWeight:'bold'}} >
          <button className='CalenderBtn' aria-label="Previous Year"  onClick={ (e) => { e.preventDefault(); decreaseYear() } } disabled={prevYearButtonDisabled}> {"<<"} </button>
          <button className='CalenderBtn' aria-label="Previous Month" onClick={ (e) => { e.preventDefault(); decreaseMonth() }} disabled={prevMonthButtonDisabled}> {"<"} </button> 
          <button className='CalenderBtn' aria-label="Next Month" onClick={ (e) => { e.preventDefault(); increaseMonth() }} disabled={nextMonthButtonDisabled}> {">"} </button>
          <button className='CalenderBtn' aria-label="Next Year" onClick={ (e) => { e.preventDefault(); increaseYear() }} disabled={nextYearButtonDisabled}> {">>"} </button>
        </div>        
      </div>
    );
  };

  const customFooter = () => {
    return (
      <div className="calendar-footer" >
        <button className='CalenderBtn btn-today'  onClick={handleTodayButtonClick}>Today</button>
        <button className='CalenderBtn btn-clear'  onClick={handleClearButtonClick}>Clear</button>
        <button className='CalenderBtn close-btn'  onClick={handleCloseButtonClick}>Close</button>
      </div>
    );
  }

  return (
    <>
    <div style={{ position: 'relative'}}>
      <div style={{ display: "flex", maxWidth: "250px",}}>
       <DatePicker 
        className='custom-datepicker'
        id="effectiveDate"
        ref={DatePickerRef}
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM-dd-yyyy"
        renderCustomHeader={CustomHeader}
        calendarClassName="custom-datepicker-popup"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        open= { calenderOpen}
        onClickOutside={() => setCalenderOpen(false)}
        onCalendarOpen={handleCalendarOpen}
        ariaDescribedBy='datehelp errors-effectiveDate'
        autoComplete='off'
       > 
        {customFooter()}
       </DatePicker> 
       <button  id="calenderOpenBtn" type="button" onClick={handleOpenButtonClick} className='CalenderBtn' aria-label="HDHP Effective Date Calender"> 
       <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 448 512"> <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
       </button> 
       <OfsInfoTooltip text="Select the month, day and year your HDHP plan goes or went into effect. This information can be used to help determine what your eligible medical expenses are." 
       tooltipId="HDHPTooltip"
       />

       </div>
    </div>


    {(isFormSubmit || isOnChange === 'blur') && selectedDate === null && <div className="has-error">
    <div className="help-block input-error-msg HDHPerror">HDHP effective date is <br /> required.</div></div>}


    {!!selectedDate  && (
  <div className="has-error">
    {selectedDate && !isDateValid && (
      <div className="help-block input-error-msg">Date must be entered as mm-dd-yyyy.</div>
    )}
  </div>
)}

  </>
  );


};

export default CustomCalender;