import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./Header";
import MainContent from "./MainContent";
import Footer from "./Footer";
import "./styles.css";
import { publishPostPageData } from "../services/analyticsService";

function Welcome() {
  const navigate = useNavigate();
  const location = useLocation();
  const [group, setGroup] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const group_num = params.get("group");
    if (group_num) {
      setGroup(group_num);
    }
  }, [location.search]);

   useEffect(() => {
    window.pageDataLayer.content.pageName="welcome";
    publishPostPageData(window.pageDataLayer.content, "trackPageView")
 }, []);


  const handleNextClick: any = () => {
    if (group){
      navigate("./accountholder?group=" + group);
    }
    else{
      navigate("./accountholder"); 
    }
  };
  return (
    <>
      <Header />
      <div className="container-fluid update">
        <MainContent handleNextClick={handleNextClick} />
        <Footer />
      </div>
    </>
  );
}

export default Welcome;
