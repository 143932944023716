import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAarp } from "../components/hooks/useAarp";
import EnrollmentModal from "./modal/cancelEnrollment";
import  {SubmitCallback} from "./Account";
import {AccountData, AdditionalCardDetails} from "./modal/enrollmentData";
import { publishPostPageData } from "../services/analyticsService";
import Alerts, { AlertProps } from './modal/Alerts';


const AdditionalCard:React.FC<{accountData:AccountData | undefined, updateGlobalData: (updatedData: AccountData) => void, onSubmit: SubmitCallback, currentStage: number, setCurrentStage: React.Dispatch<React.SetStateAction<number>> }> = ({accountData, updateGlobalData, onSubmit, currentStage, setCurrentStage }) => {
  const { isAarp } = useAarp();

  const [alerts, setAlerts] = useState<AlertProps[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AdditionalCardDetails>(
    {
      defaultValues: {
      firstName: accountData?.additionalCard.firstName ? accountData?.additionalCard.firstName : "",
      middleInitial: accountData?.additionalCard.middleInitial ? accountData?.additionalCard.middleInitial : "",
      lastName: accountData?.additionalCard.lastName ? accountData?.additionalCard.lastName : "",
    },
      mode: "all",
    }
  );
  const [requestAdditionalCard, setRequestAdditionalCard] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const handleCheckboxChange = () => {
    setRequestAdditionalCard(!requestAdditionalCard);
  };


  useEffect(() => {
    if(accountData?.requestAdditionalCard){
        setRequestAdditionalCard(true);
      }
    window.pageDataLayer.content.pageName="additional cardholder";
    publishPostPageData(window.pageDataLayer.content, "hsastep2")
// eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

    // Function to add a new alert
    const addAlert = (type: string, title: string, msg: string) => {
      const newAlert: AlertProps = {
        type,
        title,
        msg,
        index: alerts.length,
        closeAlert: closeAlert, // Pass the closeAlert function to each alert
      };
      setAlerts([...alerts, newAlert]);
    };
  
    // Function to close an alert
    const closeAlert = (index: number) => {
      const updatedAlerts = alerts.filter((alert, i) => i !== index);
      setAlerts(updatedAlerts);
    };

  const handleCancel = () => {
    // Handle cancellation
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleKeyDown =
    (pattern: RegExp) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;

      // Ignore special keys, except for 'space'
      if (key !== " " && key.length === 1 && !key.match(pattern)) {
        event.preventDefault();
        return;
      }
    };

  const handleBackClick = (data: any) => {
    if(requestAdditionalCard){
      updateGlobalData({
        ...accountData!,
        additionalCard: {
          firstName: data.firstName,
          middleInitial: data.middleInitial,
          lastName: data.lastName,
        },
        requestAdditionalCard: requestAdditionalCard,
      });
    }
   
    // Handle going back
    onSubmit("Stage 2 back");
    setCurrentStage(1);
  };

  const handleNextClick = (data: any) => {

    if (
      (accountData!.firstName?.toUpperCase() === data.firstName?.toUpperCase() &&
      accountData!.middleInitial?.toUpperCase() === data.middleInitial?.toUpperCase() &&
      accountData!.lastName?.toUpperCase() === data.lastName?.toUpperCase())
    ) {
      if(alerts.length === 0){
      addAlert('danger',
        'Please enter the name of the additional cardholder.',
        'A debit card will automatically be ordered as part of the enrollment process.'
      );
      }
      return;
      }
    updateGlobalData({
      ...accountData!,
      additionalCard: {
        firstName: data.firstName,
        middleInitial: data.middleInitial,
        lastName: data.lastName,
      },
      requestAdditionalCard: requestAdditionalCard,
    });
    onSubmit("Stage 2");
    setCurrentStage(3);
  };

  return (
    <>
    <main role="main">
      <section className="view-additionalcard">
        <div className="view-masthead">
          <h1>
            <span className="hidden-xs">Step 2: </span>Additional Cardholder
          </h1>
          <p className="required">
            All fields required except where indicated.
          </p>
        </div>
       
        <div className="ofs-alerts" style={{ display: alerts.length > 0 ? 'block' : 'none' }}>
          <Alerts alerts={alerts} closeAlert={closeAlert} />
      </div>
        <form
          className="form-horizontal"
        >
          <fieldset>
          <h2 id="additionalcardH" className="AddCardHeaders">Additional Cardholder&nbsp;Info</h2>

            <div className="row">
              <div className="col-sm-10 col-md-8 col-lg-6">
                <p>
                  A Debit Card will be provided to you automatically when your
                  account is opened. To request an additional Debit Card for an
                  authorized user for your account, please add the cardholder
                  information below.
                </p>
              </div>
            
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label htmlFor="addCardholder">
                  <input
                    type="checkbox"
                    name="addCardholder"
                    id="addCardholder"
                    value="true"
                    checked={requestAdditionalCard}
                    onChange={handleCheckboxChange}
                  />
                  Add additional cardholder
                </label>
              </div>

            </div>
            {requestAdditionalCard && (
              <>
                <div className="form-group">
                  <label htmlFor="firstName" className="control-label">
                    First name
                  </label>
                  <div className="control-input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      maxLength={18}
                      aria-describedby="ADCerrors-firstName"
                      {...register("firstName", {
                        required: "First name is required.",
                        maxLength: {
                          value: 18,
                          message: "No more than 18 chars allowed.",
                        },
                        validate: {
                          hasFakeCharacters: (value: string) => {
                            const REGEX = /^[a-zA-Z\s-]+$/;
                            return (
                              REGEX.test(value.replace(/-/g, "")) || "Please enter a valid Name."
                            );
                          },
                        }
                      })}
                      autoComplete="off"
                      onKeyDown={handleKeyDown(/[a-zA-Z\s-]/)}
                    />
                    {errors.firstName && (
                      <div className="has-error" id="ADCerrors-firstName" aria-live="assertive">
                        <div className="help-block input-error-msg">
                          {errors.firstName.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="middleInitial" className="control-label">
                    Middle initial <small>(optional)</small>
                  </label>
                  <div className="control-input-group">
                    <input
                      type="text"
                      className="form-control input-1"
                      id="middleInitial"
                      maxLength={1}
                      onKeyDown={handleKeyDown(/[a-zA-Z]/)}
                      {...register("middleInitial", {
                        maxLength: {
                          value: 1,
                          message: "No more than 1 chars allowed.",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="lastName" className="control-label">
                    Last name
                  </label>
                  <div className="control-input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      maxLength={18}
                      minLength={3}
                      aria-describedby="ADCerrors-lastName"
                      {...register("lastName", {
                        required: "Last name is required.",
                        maxLength: {
                          value: 18,
                          message: "No more than 18 chars allowed.",
                        },
                        validate: {
                          hasFakeCharacters: (value: string) => {
                            const REGEX = /^[a-zA-Z\s-]+$/;
                            return (
                              REGEX.test(value.replace(/-/g, "")) || "Please enter a valid Name."
                            );
                          },
                        }
                      })}
                      autoComplete="off"
                      onKeyDown={handleKeyDown(/[a-zA-Z\s-]/)}
                    />
                    {errors.lastName && (
                      <div className="has-error" id="ADCerrors-lastName" aria-live="assertive">
                        <div className="help-block input-error-msg">
                          {errors.lastName.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {!requestAdditionalCard && (
              <>
                <div className="form-group">
                  <label htmlFor="firstName_disabled" className="control-label">
                    First name
                  </label>
                  <div className="control-input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="firstName_disabled"
                      maxLength={18}
                      readOnly={true}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="middleInitial_disabled" className="control-label">
                    Middle initial <small>(optional)</small>
                  </label>
                  <div className="control-input-group">
                    <input
                      type="text"
                      className="form-control input-1"
                      name="middleInitial_disabled"
                      id="middleInitial_disabled"
                      maxLength={1}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="lastName_Disabled" className="control-label">
                    Last name
                  </label>
                  <div className="control-input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="lastName_Disabled"
                      maxLength={18}
                      minLength={3}
                      readOnly={true}
                    />
                  </div>
                </div>
              </>
            )}
          </fieldset>

          <div className="form-group">
            <div className="block-actions control-input-group">
              <button
                type="submit"
                id="js-button__previous"
                className="btn btn-default"
                onClick={handleSubmit(handleBackClick)}
              >
                Previous
              </button>
              <button
                type="submit"
                id="js-button__next"
                className="btn btn-primary"
             onClick={handleSubmit(handleNextClick)}
              >
                Next
              </button>
              
              {isAarp && (
                <>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="btn btn-primary"
                  >
                    Cancel Enrollment
                  </button>
                  <EnrollmentModal
                    show={showModal}
                    onClose={handleCloseModal}
                  />
                </>
              )}
            </div>
          </div>
        </form>
    
      </section>
      </main>
      </>
  );
};

export default AdditionalCard;
