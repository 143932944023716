import React, { useEffect, useState } from "react";
import { SubmitCallback } from "./Account";
import enrollmentService from "../services/enrollmentService";
import { AccountData } from "./modal/enrollmentData";
import { useAarp } from "./hooks/useAarp";
import { publishPostPageData } from "../services/analyticsService";
import LoaderModal from "./../components/modal/LoaderModel";

// interface ThankyouControllerProps {
//   enrollResponse: string;
//   defaultCtrl: {
//     theme: {
//       url: string;
//       brandName: string;
//     };
//   };
// }

const ThankYou: React.FC<{
  accountData: AccountData | undefined;
  onSubmit: SubmitCallback;
}> = ({ accountData, onSubmit }) => {
  const [enrollResponse, setEnrollResponse] = useState<string>("");
  const { isAarp } = useAarp();

  useEffect(() => {
    window.pageDataLayer.content.pageName="thank you";
    publishPostPageData(window.pageDataLayer.content, "hsathankyou")
 }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountData) {
          const response = await enrollmentService.enroll(
            process.env.REACT_APP_ENROLL!,
            accountData as AccountData
          );
          if (response === "success") {
            setEnrollResponse(
              accountData.tos.requestElectronic ? "paperless" : "mail"
            );
          } else if (response === "partial") {
            setEnrollResponse("vetting");

            if (isAarp) {
              window.location.href =
                "https://memberforms-aarp.optum.com/HSA-Enrollment-Vetting-AARP-Form.html";
            } else {
              window.location.href =
                "https://memberforms.optum.com/HSA-Enrollment-Vetting-Form.html";
            }
          }
        }
      } catch (error) {
        setEnrollResponse("failure");
      }
    };
    fetchData();
  }, [isAarp, accountData]);

  const renderResponse = () => {
    switch (enrollResponse) {
      case "paperless":
        return (
          <div className="response-paperless">
            <div className="view-masthead">
              <h1>Welcome!</h1>
            </div>
            <h2>
              Thank you for applying for an Optum Bank health savings account.
            </h2>
            <p>
              You will receive an email within 3-5 business days that contains a
              link and instructions on how to access your account online at
              Optumbank.com. Included in these instructions are details to
              locate your Optum Bank welcome kit. Your welcome kit contains:
            </p>
            <ul>
              <li>A welcome letter which includes your account number</li>
              <li>Tips for getting started with your HSA</li>
              <li>Examples of qualified and nonqualified medical expenses</li>
              <li>Guidelines for assigning a beneficiary to your account</li>
              <li>Important banking disclosures for your records</li>
            </ul>
            <p>
              You will also receive your debit card in the mail within 8
              business days.
            </p>
            <p>
              Want to jumpstart your HSA education?{" "}
              <a
                href="https://www.optumbank.com/customer-support/opening-closing-your-hsa/new-account-holder-checklist.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check out our new account holder checklist.
              </a>
            </p>
          </div>
        );
      case "mail":
        return (
          <div className="response-mail">
            <div className="view-masthead">
              <h1>Welcome!</h1>
            </div>
            <h2>
              Thank you for applying for an Optum Bank health savings account.
            </h2>
            <p>
              Your welcome kit will be mailed within 5-7 business days. It will
              include details on how to access your account online at
              Optumbank.com as well as:
            </p>
            <ul>
              <li>A welcome letter which includes your account number</li>
              <li>Tips for getting started with your HSA</li>
              <li>Examples of qualified and nonqualified medical expenses</li>
              <li>Guidelines for assigning a beneficiary to your account</li>
              <li>Important banking disclosures for your records</li>
            </ul>
            <p>
              You will also receive your debit card in the mail within 8
              business days.
            </p>
            <p>
              Want to jumpstart your HSA education?{" "}
              <a
                href="https://www.optumbank.com/customer-support/opening-closing-your-hsa/new-account-holder-checklist.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check out our new account holder checklist.
              </a>
            </p>
          </div>
        );
      case "vetting":
        return (
          <div className="response-vetting">
            {/* The content for this section goes here */}
          </div>
        );
      case "failure":
        return (
          <div className="response-failure">
            <div className="view-masthead">
              <h1>We Apologize.</h1>
            </div>
            <h2 className="text-danger">
              Our system is experiencing technical difficulties and we are
              unable to process your application.
            </h2>
            <p>Please try again later.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {enrollResponse === "" && < LoaderModal  />}
      <section className="view-thankyou">
        <section id="response">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              {renderResponse()}
            </div>
          </div>
          <div className="block-actions">
            {enrollResponse !== "vetting" && (
              <a
                href={'http://www.optumbank.com/'}
                className="btn btn-primary"
                title={`Return to http://www.optumbank.com/}`}
              >
                Exit
              </a>
            )}
          </div>
        </section>
      </section>
    </>
  );
};

export default ThankYou;
