export interface AccountHolder {
    firstName: string;
    middleInitial: string;
    lastName: string;
    ssn: string;
    dob: string;
    aarpNum: string;
    homePhone: string;
    workPhone: string;
    email: string;
    remail: string;
    verificationCode: string;
    homeAddressLine1: string;
    homeAddressCity: string;
    zip: string;
    zipplus4: string;
    mailingAddressLine1: string;
    mailingAddressCity: string;
    mailingState: string;
    mailingzip: string;
    mailingzipplus4: string;
    employerGroup: string;
    employerName: string;
    coverage: string;
    effectiveDate: string;
    homeState: string;
  }
  
 export interface State {
    abrv: string;
    name: string;
  }


export interface AdditionalCardDetails {
    firstName: string;
    middleInitial: string;
    lastName: string;
  }

  export interface Tos {
    accepted: boolean;
    requestElectronic: boolean;
    requestWelcomeKit: boolean;
    firstName: string;
    middleInitial: string;
    lastName: string;
}

export interface Group {
    id: string;
    name: string;
    coverage: string;
    effectiveDate: string;
}

export interface Address {
    line1: string;
    city: string;
    zip: string;
    state: string;
    zipplus4: string;
}

export interface AccountData {
    firstName: string;
    lastName: string;
    middleInitial: string;
    homePhone: string;
    workPhone: string;
    ssn: string;
    dob: string;
    aarpNum: string;
    email: string;
    remail: string;
    verificationCode: string;
    individualEnrollment: boolean;
    group: Group;
    homeAddress: Address;
    useAltMailingAddress: boolean;
    mailingAddress: Address;
    requestAdditionalCard: boolean;
    additionalCard: AdditionalCardDetails;
    tos: Tos;
}

export function mapAccountHolderToAccountData(accountHolder: AccountHolder): AccountData {

    return {
        firstName: accountHolder.firstName,
        lastName: accountHolder.lastName,
        middleInitial: accountHolder.middleInitial,
        homePhone: accountHolder.homePhone,
        workPhone: accountHolder.workPhone,
        ssn: accountHolder.ssn,
        dob: accountHolder.dob,
        aarpNum: accountHolder.aarpNum,
        email: accountHolder.email,
        remail: accountHolder.remail,
        verificationCode: accountHolder.verificationCode,
        individualEnrollment: true,
        group: {
            id: accountHolder.employerGroup,
            name: accountHolder.employerName,
            coverage: accountHolder.coverage,
             effectiveDate: accountHolder.effectiveDate
        },
        homeAddress: {
            line1: accountHolder.homeAddressLine1,
            city: accountHolder.homeAddressCity,
            zip: accountHolder.zip,
            state: accountHolder.homeState,
            zipplus4: accountHolder.zipplus4
        },
        useAltMailingAddress: accountHolder.mailingAddressLine1 !== "",
        mailingAddress: {
            line1: accountHolder.mailingAddressLine1,
            city: accountHolder.mailingAddressCity,
            zip: accountHolder.mailingzip,
            state: accountHolder.mailingState,
            zipplus4: accountHolder.mailingzipplus4
        },
        requestAdditionalCard: false,
        additionalCard: {
            firstName: "",
            middleInitial: "",
            lastName: ""
        },
        tos: {
            accepted: false,
            requestElectronic: false,
            requestWelcomeKit: false,
            firstName: accountHolder.firstName,
            middleInitial: accountHolder.middleInitial,
            lastName: accountHolder.lastName
        }
    };
}

export interface AnalyticsContent {
    pageName: string;
    kpi?: {
        name: string;
        type: string;
        activity: string;
    };
}

export function useStateArray(): State[] {
    return [
        { abrv: "", name: "Please Choose" },
        { abrv: "AL", name: "Alabama" },
        { abrv: "AK", name: "Alaska" },
        { abrv: "AZ", name: "Arizona" },
        { abrv: "AR", name: "Arkansas" },
        { abrv: "CA", name: "California" },
        { abrv: "CO", name: "Colorado" },
        { abrv: "CT", name: "Connecticut" },
        { abrv: "DC", name: "Washington, DC" },
        { abrv: "DE", name: "Delaware" },
        { abrv: "FL", name: "Florida" },
        { abrv: "GA", name: "Georgia" },
        { abrv: "HI", name: "Hawaii" },
        { abrv: "ID", name: "Idaho" },
        { abrv: "IL", name: "Illinois" },
        { abrv: "IN", name: "Indiana" },
        { abrv: "IA", name: "Iowa" },
        { abrv: "KS", name: "Kansas" },
        { abrv: "KY", name: "Kentucky" },
        { abrv: "LA", name: "Louisiana" },
        { abrv: "ME", name: "Maine" },
        { abrv: "MD", name: "Maryland" },
        { abrv: "MA", name: "Massachusetts" },
        { abrv: "MI", name: "Michigan" },
        { abrv: "MN", name: "Minnesota" },
        { abrv: "MS", name: "Mississippi" },
        { abrv: "MO", name: "Missouri" },
        { abrv: "MT", name: "Montana" },
        { abrv: "NE", name: "Nebraska" },
        { abrv: "NV", name: "Nevada" },
        { abrv: "NH", name: "New Hampshire" },
        { abrv: "NJ", name: "New Jersey" },
        { abrv: "NM", name: "New Mexico" },
        { abrv: "NY", name: "New York" },
        { abrv: "NC", name: "North Carolina" },
        { abrv: "ND", name: "North Dakota" },
        { abrv: "OH", name: "Ohio" },
        { abrv: "OK", name: "Oklahoma" },
        { abrv: "OR", name: "Oregon" },
        { abrv: "PA", name: "Pennsylvania" },
        { abrv: "RI", name: "Rhode Island" },
        { abrv: "SC", name: "South Carolina" },
        { abrv: "SD", name: "South Dakota" },
        { abrv: "TN", name: "Tennessee" },
        { abrv: "TX", name: "Texas" },
        { abrv: "UT", name: "Utah" },
        { abrv: "VT", name: "Vermont" },
        { abrv: "VA", name: "Virginia" },
        { abrv: "WA", name: "Washington" },
        { abrv: "WV", name: "West Virginia" },
        { abrv: "WI", name: "Wisconsin" },
        { abrv: "WY", name: "Wyoming" },
        { abrv: "FM", name: "Federated States of Micronesia" },
        { abrv: "GU", name: "Guam" },
        { abrv: "MH", name: "Marshall Islands" },
        { abrv: "MP", name: "Northern Mariana Islands" },
        { abrv: "PW", name: "Palau" },
        { abrv: "VI", name: "Virgin Islands" },
        { abrv: "AA", name: "Armed Forces Americas" },
        { abrv: "AE", name: "Armed Forces Europe" },
        { abrv: "AP", name: "Armed Forces Pacific" },
    ];
}