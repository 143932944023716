import React, { useEffect, useRef } from 'react';

interface EnrollmentModalprops { 
    show: boolean;
    onClose: () => void;

}

const EnrollmentModal: React.FC<EnrollmentModalprops> = ({show, onClose }) => {
  const yesButtonRef = useRef<HTMLButtonElement>(null);
  const noButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if(event.key === 'Tab') {
      const focusableModalElements = [yesButtonRef.current, noButtonRef.current];
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[focusableModalElements.length - 1];

      if(!event.shiftKey && document.activeElement === lastElement) {
        firstElement?.focus();
          event.preventDefault();
      } else if(event.shiftKey && document.activeElement === firstElement) {
        lastElement?.focus();
        event.preventDefault();
        }
      
    }
  };

  if(show) {
    document.addEventListener('keydown', handleKeyDown);
  }

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  }
}, [show]);

  useEffect(() => {
    if (show && yesButtonRef.current) {
      yesButtonRef.current.focus();
    }
  }, [show]);

    const redirectToHome = () => {
      window.location.href = '/';
    };

    return (
      
        <div className={`enrollmodal modal ${show ? 'show' : ''}`} 
        autoFocus={false}
        aria-modal="true" 
        role="dialog"
        tabIndex={-1}
        >
        <div className="modal-dialog" role="group" aria-label="Cancel Enrollment" >
          <div className="modal-content cancelmodal" aria-modal="true">
            <div className="modal-body" id="CancelEnrollmentModal" >
              Are you sure you want to cancel enrollment?
            </div>
            <div className="modal-footer">
                <button ref={yesButtonRef} type="button" className="btn btn-primary enrollbutton " aria-label="Are you sure you want to cancel? Yes"
                onClick={redirectToHome}>
                  Yes
                </button>
              <button ref={noButtonRef}  type="button" className="btn btn-primary enrollbutton" onClick={onClose} aria-label="Are you sure you want to cancel? No">
                No
              </button>
              
            </div>
          </div>
        </div>
      </div>
    );
};

export default EnrollmentModal;