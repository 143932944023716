import {AccountData} from "../components/modal/enrollmentData";
import moment from "moment";
 

interface EmployerData {
  branchNumber: string;
  name: string;
  groupId: string;
  employerStatus: string;
  branch: string;
  investmentFeeAmt: string;
  investmentThresholdAmt: string;
  totalMonthlyMaintFee: string;
  invstAllwInd: string;
  acctHolderMaintFee: string;
  invstFeeType: string;
  acctHolderBalEnd: string;
  fulfillmentText: string;
}


interface CaptchaResponse {
  // Define the structure of captcha response
  // Adjust the types according to the actual structure
}

interface EnrollmentService {
  getEmployer(url: string, group: string): Promise<EmployerData>;
  verifySSN(url: string, ssn: string): Promise<boolean | string>;
  enroll(url: string, enrollmentData: AccountData): Promise<string>;
  verifyAARP(url: string, obj: any): Promise<boolean | string>; // Adjust the type according to the actual response
  submitCaptcha(url: string, obj: any): Promise<CaptchaResponse | string>; // Adjust the type according to the actual response
}

const enrollmentService: EnrollmentService = {
  async getEmployer(url: string, group: string): Promise<EmployerData> {
    try {
      const response = await fetch(`${url.replace("{id}", group)}`, {
        cache: "no-cache",
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Employer not found");
        } else {
          throw new Error("Network error");
        }
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async verifyAARP(url: string, obj: any): Promise<boolean | string> {
    try {
      const response = await httpPost(url, obj);
      if(response.ok){
        const isValid=  await response.text();

        return isValid === "valid";
      }
   
      return false;
    
    } catch (error) {
      return "Network error. API is down. Contact Faro team";
    }
  },

  async verifySSN(url: string, ssn: string):Promise<boolean | string> {
    try {
      const response = await httpPost(url, {
        ssn,
      });
     
      if(response.ok){
        const isEnrolled= await response.text();

        return isEnrolled === "valid"
      }
  
      return false;
    } catch (error) {
      throw new Error("Network error");
    }
  },

  async enroll(url: string, enrollmentData: AccountData): Promise<string> {
    try {

      enrollmentData.homePhone = enrollmentData.homePhone.replace(/-/g, '');
      enrollmentData.workPhone = enrollmentData.workPhone.replace(/-/g, '');
      enrollmentData.ssn = enrollmentData.ssn.replace(/-/g, '');
         // Copy physical address to mailing address if not different
    if(enrollmentData.useAltMailingAddress === false){
      enrollmentData.mailingAddress.line1 = enrollmentData.homeAddress.line1;
      enrollmentData.mailingAddress.city = enrollmentData.homeAddress.city;
      enrollmentData.mailingAddress.state = enrollmentData.homeAddress.state;
      enrollmentData.mailingAddress.zip = enrollmentData.homeAddress.zip;
      enrollmentData.mailingAddress.zipplus4 = enrollmentData.homeAddress.zipplus4;
  }

  // Format the HDHP effective date
  if (enrollmentData.group.effectiveDate === '') {
      // The effective date for AARP will be set to the first of the year in
      // api/services/enrollment-service.js
      enrollmentData.group.effectiveDate = moment(new Date()).format('YYYY-MM-DD');
  } else {
    enrollmentData.group.effectiveDate = moment(enrollmentData.group.effectiveDate).format('YYYY-MM-DD');
  }

  // Format the dob date
  enrollmentData.dob = moment(enrollmentData.dob, 'MM-DD-YYYY').format('YYYY-MM-DD');
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(enrollmentData),
      });
      if (!response.ok) {
        throw new Error("Network error");
      }
      const data = await response.text();
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async submitCaptcha(
    url: string,
    obj: any
  ): Promise<CaptchaResponse | string> {
    try {
      const data = await httpPost(
        url,
        { captcha: obj }
      );
      if(data.ok){
        const response = await data.json();

        return response
      }
      
      return "";
    } catch (error) {
      return "Network error. API is down";
    }
  },
};

async function httpPost(url: string, data: any): Promise<any> {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network error");
    }

    return response;
  } catch (error) {
    throw error;
  }
}

export default enrollmentService;
