import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "./Footer";
import OfsInfoTooltip from "../validators/OfsInfoTooltip";
import { useForm } from "react-hook-form";
import EnrollmentModal from "./modal/cancelEnrollment";
import "react-datepicker/dist/react-datepicker.css";
import enrollmentService from "../services/enrollmentService";
import ofsDateParser from "../utility/ofsDateParser";
import CustomCalender from "./controls/DatePicker";
import AdditionalCard from "./AdditionalCard";
import ThankYou from "./ThankYou";
import Verify from "./Verify";
import TermsConditions from "./TermsConditions";
import { useLocation } from "react-router-dom";
import {
  AccountHolder,
  State,
  useStateArray,
  AccountData,
  mapAccountHolderToAccountData,
} from "./modal/enrollmentData";
import { useAarp } from "./hooks/useAarp";
import moment from "moment";
import SessTimeout from "./modal/sessionTimeout";
import FormDown from "./modal/FormDown";
import ValidateAarpModal from "./modal/ValidateAarpModal";
import { publishPostPageData } from "../services/analyticsService";
import { useGroup } from "./hooks/useGroup";
import HeaderProgressBar from "./HeaderProgressBar"; 
import { HeaderProvider } from "./controls/HeaderProvider";

export type SubmitCallback = (message: string) => void;

const Account: React.FC = () => {
  const { isAarp } = useAarp();
  const { isGroup } = useGroup();
  const [showAccountHolder, setShowAccountHolder] = useState(true);
  const [showAdditionalCard, setShowAdditionalCard] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unMaskedSsn, setUnMaskedSsn] = useState("");
  const [, setMessage] = useState(""); // message ommitted as it was declared but its value is never read. -> omitted to resolve lint warning
  const [accountData, setAccountData] = useState<AccountData>();
  const [employerName, setEmployerName] = useState("");
  const [csrMode, setCsrMode] = useState<boolean>(false);
  const [, setBlurred] = useState(false); // blurred ommitted as it was declared but its value is never read. -> omitted to resolve lint warning
  const location = useLocation();
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isAarpValid, setIsAarpValid] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(!!accountData?.mailingAddress.line1);
  const [isAarpSubmit, setIsAarpSubmit] = useState<boolean>(false);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
  const [effectiveDateValue, setEffectiveDateValue] = useState<Date | null>(accountData?.group.effectiveDate ? new Date(accountData?.group.effectiveDate) : null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isGroupNumProvided, setIsGroupNumProvided] =useState<boolean>(false);
  const [isGroupNameLoading, setIsGroupNameLoading] = useState<boolean>(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
    // `reset` removed from here because it was declared but unused
  } = useForm<AccountHolder>({
    mode: "all",
    defaultValues: {
      firstName: "",
      lastName: "",
      middleInitial: "",
      ssn: "",
      dob: "",
      aarpNum: "",
      homePhone: "",
      workPhone: "",
      email: "",
      remail: "",
      verificationCode: "",
      homeAddressLine1: "",
      homeAddressCity: "",
      zip: "",
      zipplus4: "",
      mailingAddressLine1: "",
      mailingAddressCity: "",
      mailingzip: "",
      mailingzipplus4: "",
      employerGroup: "",
      coverage: "",
      effectiveDate: "",
      employerName: "",
      homeState: "",
      mailingState: "",
    },
  });

  const [states] = useState<State[]>(useStateArray());
  const [currentStage, setCurrentStage] = useState(1);

  const handleCancelClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleKeyDown =
    (pattern: RegExp) => (event: React.KeyboardEvent<HTMLInputElement>) => {
     
      const key = event.key;
      if((event.ctrlKey|| event.metaKey )&& (key.toLowerCase() === 'a' || key.toLowerCase() === 'c' || key.toLowerCase() === 'v' || key.toLowerCase() === 'x') ){return true}

      if(key ==="Enter") {event.preventDefault(); return false}
      // Ignore special keys, except for 'space'
      if (key !== " " && key.length === 1 && !key.match(pattern)) {
        event.preventDefault();
        return;
      }
    };

    useEffect(() => {
      window.pageDataLayer.content.pageName="account holder information";
      publishPostPageData(window.pageDataLayer.content, "hsastep1")
   }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const group_num = params.get("group");
    if (group_num) {
      setIsGroupNumProvided(true);
      customEmployerGroupValidations.isValidGroupNumber(group_num);
      if(employerName && employerName.length>=1){
        setValue("employerGroup", group_num.toUpperCase());
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search,employerName]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === "Enter" &&  isAarp) {
        event.preventDefault();
        setCsrMode((prevCsrMode) => !prevCsrMode);
      }
    }; 

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isAarp]); // Empty dependency array to run effect only once

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("savEtEffectiveDateValue");
  }, []);

  useEffect(() => {
    if (csrMode) {
      const csrWarning = document.createElement("h1");
      csrWarning.innerHTML = "CSR Mode";
      csrWarning.setAttribute("id", "csr_mode_header");
      document.getElementsByClassName("view-masthead")[0].append(csrWarning);
    } else {
      const csrModeHeader = document.getElementById("csr_mode_header");
      if (csrModeHeader) csrModeHeader.remove();
    }
  }, [csrMode]);

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (unMaskedSsn) {
      event.target.value = unMaskedSsn;
      setUnMaskedSsn("");
    }
  };

  const formatSsn = (value: string): string => {
    // Remove non-numeric characters from the input value
    const numericValue = value.replace(/\D/g, "");
    // Format SSN as per XXX-XX-XXXX pattern
    return numericValue.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
  };

  const checkFornetworkError = (error: string) => {
    if (error.toLowerCase().includes("network error")) {
      setIsNetworkError(true);
    }
  };

  
  const customSsnValidations = {
    hasValidLength: (value: string) => {
      return value.length === 11 || "Please use the format 999-99-9999";
    },
    hasFakeNumber: (value: string) => {
      const REGEX = /^(.)\1+$/;
      return (
        !REGEX.test(value.replace(/-/g, "")) || "Please enter a valid SSN."
      );
    },
    duplicateSsn: (value: string) => {
      if (value && !value.startsWith("******") && value.length === 11) {
        return enrollmentService
          .verifySSN(process.env.REACT_APP_VERIFY_SSN!, value)
          .then((response) => {
            return (
              response ||
              "Our records show that you already have a Health Savings Account with us. Please contact customer service (at the number provided below) if you have any questions regarding this account."
            );
          })
          .catch((error) => {
            checkFornetworkError(error.message);
            return false;
          });
      }
    },
    maskValue: (value: string) => {
      if (value.startsWith("******") || value.length < 11) {
        return true;
      }
      setUnMaskedSsn(value);

      const maskedValue = "******-" + value.slice(7);
      var inputElement = document.querySelector(
        "input[name='ssn']"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.value = maskedValue;
      }
      return true;
    },
  };

  const customDateValidations = {
    isValidDate: (value: string) => {
      return ofsDateParser.isValidDate(value) || "Invalid date.";
    },
    isAdultDOB: (value: string) => {
      const date = new Date(ofsDateParser.formatIso(value));
      const age = new Date().getFullYear() - date.getFullYear();
      return age >= 18 || "Must be 18 or older.";
    },
    formatDate: (value: string) => {
      const date = ofsDateParser.format(value);
      // const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
      // const month = (date.getMonth() + 1).toString().length === 1 ? "0" + Number(date.getMonth() + 1) : date.getMonth() + 1;
      // const year = date.getFullYear();
      var inputElement = document.querySelector(
        "input[name='dob']"
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.value = date;
      }
      return true;
    },
  };

  const customEmployerGroupValidations = {
    hasFakeCharacters: (value: string) => {
      const REGEX = /^[a-zA-Z0-9]+$/;
      return (
        REGEX.test(value.replace(/-/g, "")) || "Please enter a Group number."
      );
    },
    isValidGroupNumber: (value: string) => {
      if (value && value.length > 1) {
        setIsGroupNameLoading(true);
        return enrollmentService
          .getEmployer(process.env.REACT_APP_GET_EMPLOYER!, value)
          .then((employerData) => {
            setIsGroupNameLoading(false);
            setEmployerName(
              employerData && employerData.employerStatus === "Active"
                ? employerData.name
                : ""
            );

            if(employerData && employerData.employerStatus === "Active"){
              setValue("employerGroup", value.toUpperCase());
            }
            return (
              (employerData && employerData.employerStatus === "Active") ||
              "Number not found. Correct your entry or contact your employer or financial advisor."
            );
          })
          .catch((error) => {
            setIsGroupNameLoading(false);
            setEmployerName("");
            checkFornetworkError(error.message);
            return "Number not found. Correct your entry or contact your employer or financial advisor.";
          });
      } else {
        setEmployerName("");
        return true;
      }
    },
  };

  const onSSNChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    event.target.value = formatSsn(value);
  };

  const onAarpNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    event.target.value = value.replace(
      /(\d{3})(\d{3})(\d{3})(\d{1})/,
      "$1-$2-$3-$4"
    );
  };

  const removeFakeCharacters = (value: string, pattern: RegExp) =>  value.replace(pattern, "");
  

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //format the phone number
    const updatedValue = removeFakeCharacters(event.target.value, /[^0-9]/g);
    event.target.value = updatedValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    );
  };

  const handleStageChange: SubmitCallback = (message) => {
    setMessage(message);
    switch (message) {
      case "Stage 2":
        setShowTermsConditions(false);
        setShowVerify(true);
        setShowAdditionalCard(false);
        setShowThankYou(false);
        break;
      case "Stage 2 back":
        setShowAccountHolder(true);
        setShowTermsConditions(false);
        setShowVerify(false);
        setShowAdditionalCard(false);
        setShowThankYou(false);
        break;
      case "Stage 3":
        setShowTermsConditions(true);
        setShowVerify(false);
        setShowAdditionalCard(false);
        setShowThankYou(false);
        break;
      case "Stage 3 back":
        setShowTermsConditions(false);
        setShowVerify(false);
        setShowAdditionalCard(true);
        setShowThankYou(false);
        break;
      case "Stage 4":
        setShowTermsConditions(false);
        setShowVerify(false);
        setShowAdditionalCard(false);
        setShowThankYou(true);
        break;
      case "Stage 4 back":
        setShowTermsConditions(false);
        setShowVerify(true);
        setShowAdditionalCard(false);
        setShowThankYou(false);
        break;
      default:
        setShowTermsConditions(false);
        setShowVerify(false);
        setShowAdditionalCard(false);
        setShowThankYou(false);
        break;
    }
  };

  const updateGlobalData = (updatedData: AccountData) => {
    setAccountData(updatedData);
  };

  const onSubmit = (data: AccountHolder) => {
    if(isGroupNameLoading){
      return;
    }

    data.employerName = employerName;
   data.effectiveDate = moment(effectiveDateValue, "MM-DD-YYYY").format("YYYY-MM-DD");
    data.ssn = unMaskedSsn;
    if(!isChecked) {
      data.mailingAddressLine1 = "";
      data.mailingAddressCity = "";
      data.mailingzip = "";
      data.mailingState =  "";
      data.mailingzipplus4 = "";
    }
    let mappedData: AccountData = mapAccountHolderToAccountData(data);
    if(accountData?.requestAdditionalCard){
      mappedData.requestAdditionalCard = accountData.requestAdditionalCard;
      mappedData.additionalCard = accountData.additionalCard;
    }
    if(accountData?.tos){ 
      mappedData.tos = accountData.tos;
    }
    setAccountData(mappedData);
    if (isAarp) {
      mappedData.group.effectiveDate="";
      checkAARPNum({ account: mappedData, csr_mode: csrMode }); 
    } else {
      if (effectiveDateValue == null) {
        return false;
      }
      data.ssn = "";
      resetField("ssn", { defaultValue: "" })
      setUnMaskedSsn("")
      setShowAccountHolder(false);
      setShowAdditionalCard(true);
      setCurrentStage(2);
    }
  };

  const firstNameValidateHandle = {...register("firstName", {
    required: "First name is required.",
    maxLength: {
      value: 18,
      message: "No more than 18 chars allowed.",
    },
    validate: {
    hasFakeCharacters: (value: string) => {
      const REGEX = /^[a-zA-Z\s-]+$/;
      return (
        REGEX.test(value.replace(/-/g, "")) || "Please enter a valid Name."
      );
    },
  }
  })}

  const lastNameValidateHandle =  {...register("lastName", {
    required: "Last name is required.",
    validate: {
      hasFakeCharacters: (value: string) => {
        const REGEX = /^[a-zA-Z\s-]+$/;
        return (
          REGEX.test(value.replace(/-/g, "")) || "Please enter a valid Name."
        );
      },
    }
  },
  )}

  const ssnValidateHandle = register("ssn", {
    required: "SSN is required.",
    validate: customSsnValidations,
  });

  const dobValidateHandle =  register("dob", {
    required: "Date of birth is required.",
    validate: customDateValidations,
  })

  const homePhoneValidateHandle = register("homePhone", {
    required: "Home phone number is required.",
    validate: {
      phonenumber: (value) =>
        value.length > 9 || "Phone number must be entered as 555-555-5555.",
      isPhoneNumber: (value) => {
        const phoneRegex = /^[2-9]\d{2}-\d{3}-\d{4}$/;
        return (
          phoneRegex.test(value) || "Phone number cannot start with 0 or 1."
        );
      },
    },
  });

  function checkAARPNum(vm: { account: AccountData; csr_mode: boolean }) {
    const obj: { [key: string]: string } = {
      // Define obj as an object with string keys and string values
      firstName: vm.account.firstName,
      middleName: vm.account.middleInitial,
      lastName: vm.account.lastName,
      dateOfBirth: moment(vm.account.dob, "MM-DD-YYYY").format("YYYY-MM-DD"),
      addrLine1: vm.account.homeAddress.line1,
      city: vm.account.homeAddress.city,
      state: vm.account.homeAddress.state,
      zip: vm.account.homeAddress.zip,
    };

    if (vm.account.aarpNum !== "") {
        obj['memberId'] = vm.account.aarpNum.split('-').join('') as string;
    }
    enrollmentService.verifyAARP(process.env.REACT_APP_VERIFY_AARP!, obj).then(
      function (res) {
        /////Needs to be update the below setter with the response from the API
            // setIsAarpValid(true); 
        if (res === true) {
          setIsAarpSubmit(true);
          setIsAarpValid(true);
          
        } 
        else {
          if (vm.csr_mode) {
            
          } else {
            setIsAarpSubmit(true);
            setIsAarpValid(false);
            return false;
          }
        }
      },
      function () {
        //Need to updaate the logic
      }
    )
    .catch((error) => {
      checkFornetworkError(error.message)
    });
  }

  const aarpNumValidateHandle = register("aarpNum", {
    validate: {
      required: (value) =>{return isAarp ? value.length > 0 || "AARP number is required." : true;},
        hasFakeCharacters: (value: string) => {
         if(value && value.length>0){
          const REGEX = /^[0-9]+$/;
          return (
            REGEX.test(value.replace(/-/g, "")) || "Please enter a valid AARP Member Number."
          );
         }
        },
      pattern: (value) => {
        return (
          value.length === 0 ||
          value.length === 13 ||
          "AARP number must be entered as 123-456-789-0."
        );
      },
    },
  });


  const handleAarpModalClose = () => {
    setIsAarpSubmit(false);
    if (isAarpValid) {
      setCurrentStage(2);
      resetField("ssn", { defaultValue: "" })
      setUnMaskedSsn("")
      setShowAccountHolder(false);
      setShowAdditionalCard(true);
    }
  };
  
  return (
    <>
    <HeaderProvider>
      {isAarpSubmit && (
        <ValidateAarpModal isValidAarp={isAarpValid} onClose={handleAarpModalClose} />
      )}
      {isNetworkError && <FormDown onClose={handleCloseModal} />}
      <HeaderProgressBar currentStage={currentStage} />
      <div className="container-fluid update">
        {showAccountHolder && (
          <main className="row">
            <div className="col-xs-12">
              <section className="view-accountholder">
                <div className="view-masthead">
                  <h1>
                    <span className="hidden-xs">Step 1: </span>Account
                    Holder&nbsp;Information
                  </h1>
                  <p className="required">
                    All fields required except where indicated.
                  </p>
                </div>
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <fieldset>
                    <legend>
                      <h2>Personal Information 
                      <small>(Account&nbsp;holder)</small></h2>
                    </legend>
                    {/* value={account.firstName} */}
                    <div className="form-group">
                      <label htmlFor="firstName" className="control-label">
                        First name
                      </label>
                      <div className="control-input-group">
                        <input
                          className="form-control"
                          id="firstName"
                          maxLength={18}
                          {...firstNameValidateHandle}
                          autoComplete="off"
                          onKeyDown={handleKeyDown(/[a-zA-Z\s-]/)}
                          aria-describedby="errors-firstName"
                          aria-invalid={!!errors.firstName}
                        />
                        {errors.firstName && (
                          <div className="has-error" id="errors-firstName" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.firstName.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="middleInitial" className="control-label">
                        Middle initial <small>(optional)</small>
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          className="form-control input-1"
                          id="middleInitial"
                          pattern="[a-zA-Z]"
                          maxLength={1}
                          autoComplete="off"
                          onKeyDown={handleKeyDown(/[a-zA-Z]/)}
                         {...register("middleInitial", {
                            maxLength: {
                              value: 1,
                              message: "No more than 1 chars allowed.",
                            },
                          })}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="lastName" className="control-label">
                        Last name
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          id="lastName"
                          maxLength={18}
                         {...lastNameValidateHandle}
                          onKeyDown={handleKeyDown(/[a-zA-Z\s-']/)}
                          aria-describedby="errors-lastName"
                          aria-invalid={!!errors.lastName}
                        />
                        {errors.lastName && (
                          <div className="has-error" id="errors-lastName" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.lastName.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="ssn" className="control-label">
                        Social security number or tax&nbsp;ID
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control input-9"
                          id="ssn"
                          maxLength={11}
                          {...ssnValidateHandle}
                          onChange={(e) => {
                            onSSNChange(e);
                            ssnValidateHandle.onChange(e);
                          }}
                          onFocus={onFocus}
                          aria-describedby="errors-ssn"
                          aria-invalid={!!errors.ssn}
                        ></input>
                        <OfsInfoTooltip text="Enter your 9 digit social security number. If you are a non-resident alien, enter your tax identification number." 
                        tooltipId="ssn-tooltip" 
                        />
                        {errors.ssn && errors.ssn.message && (
                          <div className="has-error" id="errors-ssn" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.ssn.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="dob" className="control-label">
                        Date of birth
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          inputMode="numeric"
                          autoComplete="off"
                          className="form-control input-9"
                          id="dob"
                          maxLength={10}
                         {...dobValidateHandle}
                          onKeyDown={handleKeyDown(/[0-9-/]/)}
                          aria-describedby="dateofbirth errors-dob"
                          aria-invalid={!!errors.dob}
                        />
                        {errors.dob && (
                          <div className="has-error" id="errors-dob" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.dob.message}
                            </div>
                          </div>
                        )}
                        <small id="dateofbirth" className="help-block"> mm-dd-yyyy</small>
                      </div>
                      
                    </div>
                    
                    {isAarp && (
                      <div className="form-group">
                        <label htmlFor="aarpNum" className="control-label">
                          AARP Member Number
                        </label>
                        <div className="control-input-group">
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control input-9"
                            id="aarpNum"
                            aria-describedby="aarpblock errors-aarpNum"
                            aria-invalid={!!errors.aarpNum}
                            maxLength={13}
                            {...aarpNumValidateHandle}
                            onKeyDown={handleKeyDown(/[0-9]/)}
                            onChange={(e) => {
                              onAarpNumberChange(e);
                              aarpNumValidateHandle.onChange(e);
                            }}
                            disabled={isGroup}
                          />
                          {errors.aarpNum && errors.aarpNum.message && (
                            <div className="has-error" id="errors-aarpNum" aria-live="assertive">
                              <div className="help-block input-error-msg">
                                {errors.aarpNum.message}
                              </div>
                            </div>
                          )}
                          <small id="aarpblock" className="help-block">123-456-789-0</small>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="homePhone" className="control-label">
                        Home phone
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control input-9"
                          id="homePhone"
                          maxLength={12}
                          {...homePhoneValidateHandle}
                          onKeyDown={handleKeyDown(/[0-9]/)}
                          onChange={(e) => {
                            onPhoneNumberChange(e);
                            homePhoneValidateHandle.onChange(e);
                          }}
                          aria-describedby="examplenum errors-homePhone"
                          aria-invalid={!!errors.homePhone}
                        />
                        <OfsInfoTooltip text="Enter your telephone number, including the area code.  If we suspect fraudulent debit card use, we will attempt to contact you by phone to validate your transactions." 
                        tooltipId="homePhone-tooltip"
                        />
                        {errors.homePhone && (
                          <div className="has-error" id="errors-homePhone" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.homePhone.message}
                            </div>
                          </div>
                        )}
                        <small id="examplenum" className="help-block"> 555-555-5555</small>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="workPhone" className="control-label">
                        Work phone <small>(optional)</small>
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control input-9"
                          id="workPhone"
                          maxLength={12}
                          aria-describedby="examplenum2 errors-workPhone"
                          aria-invalid={!!errors.workPhone}
                          {...register("workPhone", {
                            validate: {
                              phonenumber: (value) =>
                                value.length === 0 ||
                                value.length > 9 ||
                                "Phone number must be entered as 555-555-5555.",
                              isPhoneNumber: (value) => {
                                const phoneRegex = /^[2-9]\d{2}-\d{3}-\d{4}$/;
                                return (
                                  value.length === 0 ||
                                  phoneRegex.test(value) ||
                                  "Phone number cannot start with 0 or 1."
                                );
                              },
                            },
                          })}
                          onKeyDown={handleKeyDown(/[0-9]/)}
                          onChange={onPhoneNumberChange}
                        />
                        {errors.workPhone && (
                          <div className="has-error" id="errors-workPhone" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.workPhone.message}
                            </div>
                          </div>
                        )}
                        <small id="examplenum2" className="help-block"> 555-555-5555</small>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email" className="control-label">
                        Email address
                      </label>
                      <div className="control-input-group">
                        <input
                          type="email"
                          className="form-control"
                          autoComplete="off"
                          id="email"
                          aria-describedby="errors-email"
                          aria-invalid={!!errors.email}
                          maxLength={60}
                          {...register("email", {
                            required: "Email address is required.",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$/,
                              message: "Enter a valid Email Address.",
                            },
                          })}
                          
                        />
                        <OfsInfoTooltip text="Enter an email address where we can contact you with information about your application status and other important account information." 
                        tooltipId="email-tooltip"
                        />
                        {errors.email && (
                          <div className="has-error" id="errors-email" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.email.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="remail" className="control-label">
                        Re-enter email address
                      </label>
                      <div className="control-input-group">
                        <input
                          type="email"
                          className="form-control"
                          id="remail"
                          autoComplete="off"
                          maxLength={60}
                          {...register("remail", {
                            required: "Confirm your email address.",
                            validate: {
                              matchesPreviousEmail: (value) =>
                                value === watch("email") ||
                                "Email addresses do not match.",
                            },
                          })}
                          aria-describedby="errors-remail"
                          aria-invalid={!!errors.remail}
                        />
                        {errors.remail && (
                          <div className="has-error" id="errors-remail" aria-live="assertive" >
                            <div className="help-block input-error-msg">
                              {errors.remail.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="verificationCode"
                        className="control-label"
                      >
                        Passcode
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control input-12"
                          id="verificationCode"
                          maxLength={10}
                          aria-describedby="passcodeblock errors-verificationCode"
                          aria-invalid={!!errors.verificationCode}
                          {...register("verificationCode", {
                            required: "Passcode code is required.",
                             validate: {
                            hasFakeCharacters: (value: string) => {
                              const REGEX = /^[a-zA-Z]+$/;
                              return (
                                REGEX.test(value.replace(/-/g, "")) || "Please enter a valid Passcode."
                              );
                            },
                          }
                          })}
                          onKeyDown={handleKeyDown(/[a-zA-Z]/)}
                        />
                        {errors.verificationCode && (
                          <div className="has-error" id="errors-verificationCode" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.verificationCode.message}
                            </div>
                          </div>
                        )}
                        <small id="passcodeblock" className="help-block">
                          <p>
                            Please enter a word (up to 10 letters without
                            spaces), such as your mother's maiden name, the city
                            of your birth, the name of your first pet, or other
                            word unique to you that you will remember. We may
                            ask for your passcode to verify your identity when
                            you call our customer care center.
                          </p>
                        </small>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>
                      <h2>Home Address</h2></legend>
                    <div className="form-group">
                      <label
                        htmlFor="homeAddressLine1"
                        className="control-label"
                      >
                        Home address
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          id="homeAddressLine1"
                          maxLength={30}
                          aria-describedby="homeblock errors-homeAddressLine1"
                          aria-invalid={!!errors.homeAddressLine1}
                          {...register("homeAddressLine1", {
                            required: "Home address is required.",
                            validate: {
                              hasFakeCharacters: (value: string) => {
                                const REGEX = /^[a-zA-Z0-9.#\s-]+$/;
                                return (
                                  REGEX.test(value.replace(/-/g, "")) || "Please enter a valid home address."
                                );
                              },
                              hasPOBox: (value: string) => {
                                // const pattern = /\bbox(?:\b$|([\s|\-]+)?[0-9]+)|(p[\-\.\s]*o[\-\.\s]*|(post office|post)\s)b(\.|ox|in)?\b|(^p[\.]?(o|b)[\.]?$)/i; -> unnecessary escape characters 
                                const pattern = /\bbox(?:\b$|([\s|-]+)?[0-9]+)|(p[-.\s]*o[-.\s]*|(post office|post)\s)b(\.|ox|in)?\b|(^p[.]?(o|b)[.]?$)/i;
                                return (
                                  !pattern.test(value) || "P.O. box not allowed."
                                );
                              },
                            }
                          })}
                          onKeyDown={handleKeyDown(/[a-zA-Z0-9.#\s-]/)}
                        />
                        {errors.homeAddressLine1 && (
                          <div className="has-error" id="errors-homeAddressLine1" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.homeAddressLine1.message}
                            </div>
                          </div>
                        )}
                        <small id="homeblock" className="help-block">
                          Cannot be a P.O. box.
                        </small>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="homeAddressCity"
                        className="control-label"
                      >
                        City
                      </label>
                      <div className="control-input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="homeAddressCity"
                          autoComplete="off"
                          maxLength={22}
                          aria-describedby="errors-homeAddressCity"
                          aria-invalid={!!errors.homeAddressCity}
                          {...register("homeAddressCity", {
                            required: "City is required.",
                            validate: {
                              hasFakeCharacters: (value: string) => {
                                const REGEX = /^[a-zA-Z\s-]+$/;
                                return (
                                  REGEX.test(value.replace(/-/g, "")) || "City name may only contain letters, spaces, and dashes."
                                );
                              },
                            }
                          })}
                          onKeyDown={handleKeyDown(/[a-zA-Z\s-]/)}
                        />
                        {errors.homeAddressCity && (
                          <div className="has-error" id="errors-homeAddressCity" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.homeAddressCity.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="homeState" className="control-label">
                        State
                      </label>
                      <div className="control-input-group">
                        <select
                          className="form-control"
                          id="homeState"
                          {...register("homeState", {
                            required: "State is required.",
                          })}
                          aria-describedby="errors-homeState"
                          aria-invalid={!!errors.homeState}
                        >
                          {states.map((state) => (
                            <option key={state.abrv} value={state.abrv}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        {errors.homeState && (
                          <div className="has-error" id="errors-homeState" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.homeState.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="zip" className="control-label">
                        Zip code
                      </label>
                      <div className="control-input-group inline-inputs">
                        <div className="zip-group">
                          <input
                            type="text"
                            className="form-control input-5"
                            id="zip"
                            autoComplete="off"
                            maxLength={5}
                            minLength={5}
                            pattern="[0-9]*"
                            aria-describedby="errors-zip"
                            aria-invalid={!!errors.zip}
                            {...register("zip", {
                              required: "Zip code is required.",
                              minLength: {
                                value: 5,
                                message: "Zip Code must be 5 digits.",
                              },
                              validate: { hasFakeCharacters: (value: string) => { 
                                return (
                                  /^[0-9]*$/.test(value) || "Please enter a valid Zip code."
                                );
                              }
                            }
                            })}
                            onKeyDown={handleKeyDown(/[0-9]/)}
                          />
                           <span>  -  </span>

                           <label
                            htmlFor="zipplus4"
                            className="control-label hidden"
                    >
                    Zip code +4 extension <small>(optional)</small>
                    </label>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control input-4"
                            id="zipplus4"
                            maxLength={4}
                            pattern="[0-9]*"
                            onKeyDown={handleKeyDown(/[0-9]/)}
                            aria-describedby="errors-zip4"
                            aria-label="Zip Code Extension"
                            {...register("zipplus4", {
                              validate: {
                                hasFakeCharacters: (value: string) => {
                                  return (
                                    /^[0-9]*$/.test(value) || "Please enter a valid Zip code."
                                  );
                                },
                              },
                              minLength: {
                                value: 4,
                                message: "Zip Plus 4 Code must be 4 digits.",
                              }
                            })
                            }
                          />
                        </div>
                        {errors.zip && (
                          <div className="has-error" id="errors-zip" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.zip.message}
                            </div>
                          </div>
                        )}
                        {errors.zipplus4 && (
                          <div className="has-error" id="errors-zip4" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.zipplus4.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="checkbox checkboxgroup">
                        <label htmlFor="useAltMailingAddress">
                          <input
                            type="checkbox"
                            name="useAltMailingAddress"
                            id="useAltMailingAddress"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                          />
                          <small className="help-block">Mailing address is different</small>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  {isChecked && (
                    <fieldset>
                        <legend><h2>Mailing Address</h2></legend>
                        <div className="form-group">
                          <label
                            htmlFor="mailingAddressLine1"
                            className="control-label"
                          >
                            Address
                          </label>
                          <div className="control-input-group">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              id="mailingAddressLine1"
                              maxLength={30}
                              aria-describedby="errors-mailingAddressLine1"
                              aria-invalid={!!errors.mailingAddressLine1}
                              {...register("mailingAddressLine1", {
                                required: "Address is required.",
                                validate: {
                                  hasFakeCharacters: (value: string) => {
                                    const REGEX = /^[a-zA-Z0-9.#\s-]+$/;
                                    return (
                                      REGEX.test(value.replace(/-/g, "")) || "Please enter a valid home address."
                                    );
                                  },
                                }
                              })}
                              onKeyDown={handleKeyDown(/[a-zA-Z0-9.#\s-]/)}
                            />
                            {errors.mailingAddressLine1 && (
                              <div className="has-error" id="errors-mailingAddressLine1" aria-live="assertive">
                                <div className="help-block input-error-msg">
                                  {errors.mailingAddressLine1.message}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                      <div className="form-group">
                        <label
                          htmlFor="mailingAddressCity"
                          className="control-label"
                        >
                          City
                        </label>
                        <div className="control-input-group">
                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            id="mailingAddressCity"
                            maxLength={22}
                            aria-describedby="errors-mailingAddressCity"
                            aria-invalid={!!errors.mailingAddressCity}
                            {...register("mailingAddressCity", {
                              required: "City is required.",
                              validate: {
                                hasFakeCharacters: (value: string) => {
                                  const REGEX = /^[a-zA-Z\s-]+$/;
                                  return (
                                    REGEX.test(value.replace(/-/g, "")) || "City name may only contain letters, spaces, and dashes."
                                  );
                                },
                              }
                            })}
                            onKeyDown={handleKeyDown(/[a-zA-Z\s-]/)}
                          />
                          {errors.mailingAddressCity && (
                            <div className="has-error" id="errors-mailingAddressCity" aria-live="assertive">
                              <div className="help-block input-error-msg">
                                {errors.mailingAddressCity.message}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="mailingState" className="control-label">
                          State
                        </label>
                        <div className="control-input-group">
                          <select
                            className="form-control"
                            id="mailingState"
                            defaultValue=""
                            {...register("mailingState", {
                              required: "State is required.",
                            })}
                            aria-describedby="errors-mailingState"
                            aria-invalid={!!errors.mailingState}
                          >
                            {states.map((state) => (
                              <option key={state.abrv} value={state.abrv}>
                                {state.name}
                              </option>
                            ))}
                          </select>

                          {errors.mailingState && (
                            <div className="has-error" id="errors-mailingState" aria-live="assertive">
                              <div className="help-block input-error-msg">
                                {errors.mailingState.message}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="mailingzip" className="control-label">
                          Zip code
                        </label>
                        <div className="control-input-group inline-inputs">
                          <div className="zip-group">
                            <input
                              type="text"
                              className="form-control input-5"
                              autoComplete="off"
                              id="mailingzip"
                              maxLength={5}
                              minLength={5}
                              aria-describedby="errors-mailingzip"
                              aria-invalid={!!errors.mailingzip}
                              {...register("mailingzip", {
                                required: "Zip code is required.",
                                minLength: {
                                  value: 5,
                                  message: "Zip Code must be 5 digits.",
                                },
                                validate: { hasFakeCharacters: (value: string) => { 
                                  return (
                                    /^[0-9]*$/.test(value) || "Please enter a valid Zip code."
                                  );
                                },
                              }
                              })}
                              onKeyDown={handleKeyDown(/[0-9]/)}
                            />
                            <span>  -  </span>
                             <label
                              htmlFor="mailingzipplus4"
                              className="control-label hidden"
                            >
                              Zip code +4 extension <small>(optional)</small>
                            </label>
                            <input
                              className="form-control input-4"
                              id="mailingzipplus4"
                              type="text"
                              autoComplete="off"
                              maxLength={4}
                              onKeyDown={handleKeyDown(/[0-9]/)}
                              aria-describedby="errors-mailingzip4"
                              {...register("mailingzipplus4", {
                                validate: {
                                  hasFakeCharacters: (value: string) => {
                                    return (
                                      /^[0-9]*$/.test(value) || "Please enter a valid Zip code."
                                    );
                                  },
                                },
                                minLength: {
                                  value: 4,
                                  message: "Zip Plus 4 Code must be 4 digits.",
                                }
                              })
                              }
                            />
                          </div>
                          {errors.mailingzip && (
                            <div className="has-error" id="errors-mailingzip" aria-live="assertive">
                              <div className="help-block input-error-msg">
                                {errors.mailingzip.message}
                              </div>
                            </div>
                          )}
                          {errors.mailingzipplus4 && (
                        <div className="has-error" id="errors-mailingzip4" aria-live="assertive">
                          <div className="help-block input-error-msg">
                            {errors.mailingzipplus4.message}
                          </div>
                        </div>
                          )}
                        </div>
                      </div>
                    </fieldset>
                  )}

                  <p>
                    <small className="help-block">
                      To help the government fight the funding of terrorism and
                      money laundering activities, the USA PATRIOT Act requires
                      us to obtain, verify, and record information that
                      identifies each person who opens an HSA. As a result, when
                      you open an HSA, we will ask for your name, address, date
                      of birth, Taxpayer Identification Number (“TIN”) and other
                      information that will allow us to identify you. We may
                      also ask to see your driver’s license or other identifying
                      documents. You must have a physical address in the United
                      States and we reserve the right to decline your HSA
                      request if we are not able to fully verify your personal
                      information.
                    </small>
                  </p>

                  <fieldset>
                    <legend>
                      <h2>Medical Information - High&nbsp;Deductible
                      Health&nbsp;Plan (HDHP)</h2>
                      
                    </legend>
                    {/* aarp ? "form-group hidden" : "form-group"} */}
                    <div className="form-group hidden">
                      <div className="checkbox">
                        <label htmlFor="individualEnrollment">
                          <input
                            type="checkbox"
                            name="individualEnrollment"
                            id="individualEnrollment"
                            onChange={() => {}}
                            defaultChecked={true}
                          />
                          <span>
                            Not enrolling through employer or financial advisor
                          </span>
                        </label>
                      </div>
                    </div>
                    {!isAarp && (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="employerGroup"
                            className="control-label"
                          >
                            Group number or financial advisor number
                          </label>
                          <div className="control-input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="employerGroup"
                              maxLength={9}
                              autoComplete="off"
                              aria-describedby="errors-employerGroup"
                              aria-invalid={!!errors.employerGroup}
                              {...register("employerGroup", {
                                required:
                                  "Group number or financial advisor number is required.",
                                validate: customEmployerGroupValidations,
                              })}
                              readOnly={isGroupNumProvided}
                              onChange={() => setBlurred(false)}
                              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>)=> { 
                                if(event.key ==="Enter") { 
                                  event.preventDefault() 
                                  return false}}
                              }
                            />
                            <OfsInfoTooltip text="If you are enrolling through your employer, enter the group number provided in your employer information packet or located on your medical ID card. If your financial advisor provided you a group number please enter it here." 
                            tooltipId="employerGroup-tooltip"
                            />
                            {errors.employerGroup && (
                              <div className="has-error" id="errors-employerGroup" aria-live="assertive">
                                <div className="help-block input-error-msg">
                                  {errors.employerGroup.message}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="employerName"
                            className="control-label"
                          >
                            Employer name
                          </label>
                          <div className="control-input-group">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              id="employerName"
                              value={employerName}
                              readOnly
                            />
                          </div>
                        </div> 
                      </>
                    )}

                    <div className="form-group">
                      <label htmlFor="coverage" className="control-label">
                        Who is covered?
                      </label>
                      <div className="control-input-group">
                        <select
                          className="form-control"
                          id="coverage"
                          aria-describedby="errors-coverage"
                          aria-invalid={!!errors.coverage}
                          {...register("coverage", {
                            required: "This is a required field.",
                          })}
                        >
                          <option value="">Select</option>
                          <option value="I">Individual</option>
                          <option value="F">Family</option>
                        </select>
                        <OfsInfoTooltip text="Select Family if your HDHP coverage is a family plan. Select Individual if your HDHP coverage is a single plan. This information can be used to determine what your contribution limits are for the year." 
                        tooltipId="coverage-tooltip"
                        />
                        {errors.coverage && (
                          <div className="has-error" id="errors-coverage" aria-live="assertive">
                            <div className="help-block input-error-msg">
                              {errors.coverage.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {!isAarp && (
                      <div className="form-group">
                        <label
                          htmlFor="effectiveDate"
                          className="control-label"
                        >
                          HDHP effective date
                        </label>
                        <div className="control-input-group">
                          <div className="input-group">
                            <div>
                              <CustomCalender effectiveDateValue={effectiveDateValue} isFormSubmit={isFormSubmit} setEffectiveDateValue={setEffectiveDateValue}/>
                            </div>
                            {errors.effectiveDate && (
                              <div className="has-error" id="errors-effectiveDate" aria-live="assertive">
                                <div className="help-block input-error-msg HDHPerror">
                                  {errors.effectiveDate.message}
                                </div>
                              </div>
                            )}
                            <small id="datehelp" className="help-block datehelp" > mm-dd-yyyy</small>
                          </div>
                        </div>
                      </div>
                    )}
                  </fieldset>
                  <br/>
                  <div className="form-group ">
                    <div className="block-actions control-input-group">
                      <div onClick={()=> setIsFormSubmit(true)} style={{display:'inline', width:'fit-content'}}>
                      <button type="submit" className="btn btn-primary" id="nextButton">
                        Next
                      </button></div>

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCancelClick}
                      >
                        Cancel Enrollment
                      </button>
                      <EnrollmentModal
                        show={showModal}
                        onClose={handleCloseModal}
                      />
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </main>
        )}

        {showAdditionalCard && (
          <AdditionalCard
            accountData={accountData}
            updateGlobalData={updateGlobalData}
            onSubmit={handleStageChange}
            currentStage = {currentStage}
            setCurrentStage = {setCurrentStage}
          />
        )}
        {showVerify && (
          <Verify accountData={accountData} 
          onSubmit={handleStageChange}
          currentStage = {currentStage}
          setCurrentStage = {setCurrentStage} />
        )}
        {showTermsConditions && (
          <TermsConditions
            accountData={accountData}
            updateGlobalData={updateGlobalData}
            onSubmit={handleStageChange}
            currentStage = {currentStage}
            setCurrentStage = {setCurrentStage}
          />
        )}
        {showThankYou && (
          <ThankYou accountData={accountData} 
          onSubmit={handleStageChange} />
        )}

          < SessTimeout  />
        <Footer />
      </div>
      </ HeaderProvider>
    </>
  );
};

export default Account;