import React, { useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import enrollmentService from "../services/enrollmentService";
import Loader from "./loader/Loader";
import { SubmitCallback } from "./Account";
import { useAarp } from "../components/hooks/useAarp";
import EnrollmentModal from "./modal/cancelEnrollment";
import { AccountData, Tos } from "./modal/enrollmentData";
import { publishPostPageData } from "../services/analyticsService";
import { scroller } from "react-scroll";
import ElectronicDocument from "../documents/Electronic_Document_Delivery_Requirements.pdf";

interface validateTextType {
  firstName: boolean;
  lastName: boolean;
  middleInitial: boolean;
}

const TermsConditions: React.FC<{
  accountData: AccountData | undefined;
  updateGlobalData: (updatedData: AccountData) => void;
  onSubmit: SubmitCallback;
  currentStage: number;
  setCurrentStage: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  accountData,
  updateGlobalData,
  onSubmit,
  currentStage,
  setCurrentStage,
}) => {
  const [account, setAccount] = useState<Tos>({
    accepted: false,
    requestElectronic: false,
    requestWelcomeKit: false,
    firstName: "",
    middleInitial: "",
    lastName: "",
  });

  const [isCaptchaPass, setIsCaptchaPass] = useState<boolean>(false);
  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState<boolean>(false);
  const [showElectronic, setShowElectronic] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const recaptcha = useRef<ReCAPTCHA>(null);
  const [validateText, setValidateText] = useState<validateTextType>({
    firstName: true,
    lastName: true,
    middleInitial: true,
  });
  const [showModal, setShowModal] = useState(false);
  const { isAarp } = useAarp();
  const [key, setKey] = useState(0);
  const isReCaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';

  useEffect(() => {
    // Set a timeout to stop loading after a certain period (e.g., 10 seconds)
    const timeoutId = setTimeout(() => {
      setIsCaptchaLoaded(true);
    }, 2000); // Adjust the timeout duration as needed

    return () => {
      // Cleanup: clear the timeout when the component unmounts
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    window.pageDataLayer.content.pageName = "terms and conditions";
    publishPostPageData(window.pageDataLayer.content, "hsastep4");
  }, []);

 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  // Set the group ID for the Fee Schedule (we don't record '999999' for blank entries, but need it here')
  if (accountData?.group.id !== "") {
    document.cookie = "groupID=" + accountData?.group.id;
  } else if (isAarp) {
   document.cookie = "groupID=AARP1";
// document.cookie = "groupID=" + "AARP1";
  } else {
    // document.cookie = "groupID=" + "999999";
    document.cookie = "groupID=999999";

  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, type, value } = e.target;
    setAccount({ ...account, [name]: type === "checkbox" ? checked : value });
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const check =
      name === "firstName"
        ? account.firstName === accountData?.firstName
        : name === "lastName"
        ? account.lastName === accountData?.lastName
        : account.middleInitial === accountData?.middleInitial;
    setValidateText({ ...validateText, [name]: check });
  };

  const handleBackClick = () => {
    // Handle going back
    onSubmit("Stage 4 back");
    setCurrentStage(3);
  };

  const handleScrollOnValidation = (name: string) => {
    scroller.scrollTo(name, {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -10, // Scrolls to element + 50 pixels down the page
    });
    scroller.unregister(name);
    scroller.unmount();
  };

  const checkValidation = () => {
    setIsSubmit(true);
    if (
      account.accepted &&
      account.requestElectronic &&
      ((showElectronic && account.requestWelcomeKit) || !showElectronic) &&
      !checkNameValidation.firstNameValidation &&
      !checkNameValidation.lastNameValidation &&
      isCaptchaPass
    ) {
      return true;
    }

    if (!account.accepted) {
      handleScrollOnValidation("accepted");
    } else if (!account.requestElectronic) {
      handleScrollOnValidation("requestElectronic");
    } else if (showElectronic && !account.requestWelcomeKit) {
      handleScrollOnValidation("requestWelcomeKit");
    } else if (checkNameValidation.firstNameValidation) {
      handleScrollOnValidation("firstName");
    }

    return false;
  };

  const handleNextClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (checkValidation()) {
      sessionStorage.removeItem("savEtEffectiveDateValue");
      updateGlobalData({
        ...accountData!,
        tos: {
          accepted: account.accepted,
          requestElectronic: account.requestElectronic,
          requestWelcomeKit: account.requestWelcomeKit,
          firstName: account.firstName,
          middleInitial: account.middleInitial,
          lastName: account.lastName,
        },
      });
      onSubmit("Stage 4");
      setCurrentStage(5);
    } else {
      return;
    }
  };

  const handleCancel = () => {
    // Handle cancellation
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowElectronic = () => {
    setShowElectronic(!showElectronic);
  };

  const onReCapchaChange = async () => {
    // Handle captcha change
    if (recaptcha?.current?.getValue()) {
      var response = await enrollmentService.submitCaptcha(
        process.env.REACT_APP_SUBMIT_CAPTCHA!,
        recaptcha.current.getValue()
      );
      if (response) {
        setIsCaptchaPass(true);
      } else {
        setIsCaptchaPass(false);
      }
    } else {
      //expired case
      setIsCaptchaPass(false);
    }
  };

  const handleExpired = () => {
    // When reCAPTCHA expires, reset it
    if (recaptcha.current) {
      recaptcha.current.reset();
      setIsCaptchaPass(false);
      setKey(prevKey => prevKey + 1);
    }
  };


  const checkNameValidation = useMemo(() => {
    const firstNameValidation =
      !validateText?.firstName ||
      (isSubmit &&
        (account.firstName === "" ||
          (!!account.firstName && !validateText?.firstName)));

    const lastNameValidation =
      !validateText?.lastName ||
      (isSubmit &&
        (account.lastName === "" ||
          (!!account.lastName && !validateText?.lastName)));

    const middleNameValidation =
      !validateText?.middleInitial ||
      (isSubmit && account.middleInitial !== accountData?.middleInitial);
    return { firstNameValidation, lastNameValidation, middleNameValidation };
  }, [validateText, isSubmit, account.firstName, account.lastName, account.middleInitial, accountData?.middleInitial]);

  return (
    <>
        <main role="main">
      <section className="container-fluid view view-termsconditions">
        <div className="view-masthead">
          <h1>
            <span className="hidden-xs">Step 4: </span>Terms and Conditions
          </h1>
          <p className="required">
            <span className="cux-icon-asterisk"></span>
            <span className="hidden">*</span> Required
          </p>
        </div>
        <form name="termsForm" noValidate>
          {/* Terms and Conditions Section */}
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-5">
              <fieldset role="group" aria-labelledby="termsandcondition">
                <h2 id="termsandcondition" className="TandCHeaders">Terms and Conditions</h2>

                <p>
                  In order to view the PDF documents listed below, you must have
                  Adobe Reader installed on your computer. If you do not
                  currently have this software installed on your computer,
                  please{" "}
                  <a
                    href="https://get.adobe.com/reader/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    download Adobe Reader
                  </a>
                  .
                </p>

                <p>
                  The following documents contain important legal information
                  regarding your account. Please read them carefully and keep a
                  copy for your records.
                </p>

                <ul className="list-files">
                  {isAarp ? (
                    <li>
                      <a
                        className="file-pdf"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.optum.com/content/dam/optum/consumer-activation/unknown/AARP_Custodial_Agreement_Portal.pdf"
                      >
                        HSA Agreement and Optum Wallet Agreement and Fees
                      </a>
                    </li>
                  ) : (
                    <>
                      <li>
                        <a
                          className="file-pdf"
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.optumbank.com/content/dam/optumbank/resources/ns/045-0131-custodial-and-deposit-agreement.pdf"
                        >
                          Custodial and Deposit Agreement for HSA
                        </a>
                      </li>
                      <li>
                        <a
                          className="file-pdf"
                          id="feeSchedule"
                          target="_blank"
                          rel="noreferrer"
                          href="/feeschedule"
                        >
                          Schedule of Fees and Charges for HSA
                        </a>
                      </li>
                    </>
                  )}
                  {isAarp ? (
                    <li>
                      <a
                        className="file-pdf"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.optum.com/content/dam/optum/consumer-activation/unknown/AARP_Privacy_Notice_Portal.pdf"
                      >
                        Privacy Notice
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        className="file-pdf"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.optumbank.com/content/dam/optumbank/resources/ns/045-0132-Privacy-Notice.pdf"
                      >
                        Privacy Notice
                      </a>
                    </li>
                  )}
                </ul>

                <p>By signing below, I acknowledge and certify that:</p>

                <ul>
                  <li>
                    I wish to establish a health savings account ("HSA") with
                    Optum Bank as custodian.
                  </li>
                  <li>
                    I understand the eligibility requirements for deposits made
                    to my HSA and state that I qualify to make deposits to this
                    account or I am opening the account solely for the purpose
                    of making a rollover contribution or for a transfer of
                    assets and will not make disqualified deposits into this
                    account. I have reviewed this application and understand and
                    agree that my HSA will be opened under and governed by Optum
                    Bank's Custodial and Deposit Agreement and that the terms
                    and conditions therein will be binding on me. This document
                    will be sent to me when my account is opened, along with
                    Optum Bank's Privacy Policy and Schedule of Fees.
                  </li>
                  <li>
                    I authorize Optum Bank to provide information about my HSA,
                    including my account number, to my employer (if applicable)
                    and those acting on behalf of my employer or Optum Bank (if
                    applicable), in connection with the establishment and
                    maintenance of my HSA.
                  </li>
                  <li>
                    I acknowledge that my employer and all others acting on
                    behalf of my employer (if applicable), may provide
                    information on my behalf to establish and maintain my HSA
                    and authorize my employer and its designee to take such
                    action deemed necessary and appropriate by my employer to
                    administer my HSA, including but not limited to,
                    effectuating deposits and correcting errors where necessary.
                  </li>
                  <li>
                    I understand my monthly account statements will be made
                    available to me electronically. I agree to notify Optum Bank
                    if I wish to have statements mailed to my home address.
                  </li>
                  <li>
                    I have requested a Health Savings Account (HSA) Debit
                    MasterCard® and if I have filled out the information to
                    request an additional debit card, I hereby request Optum
                    Bank to issue a debit card on my account to the person
                    indicated and I acknowledge I will be liable for the use of
                    the debit card by the Authorized User.
                  </li>
                  <li>
                    I certify that the information provided in this application
                    is true and complete.
                  </li>
                </ul>

                <div className="form-group">
                  <div className="col-sm-12">
                    <div className="checkbox">
                      <label htmlFor="accepted">
                        <input
                          type="checkbox"
                          name="accepted"
                          id="accepted"
                          required
                          checked={account.accepted}
                          onChange={handleChange}
                          aria-describedby="acceptTandC"
                        />
                        <span className="cux-icon-asterisk"></span>
                        <span className="hidden">*</span> I accept and agree to
                        the terms and conditions as outlined above1.
                      </label>
                      {!account.accepted && isSubmit && (
                        <div className="require-error" id="acceptTandC" role="alert" aria-live="assertive" >
                          You must accept and agree to the terms and conditions
                          to proceed.
                        </div>
                      )}

                      {/* <div ofs-validation-msgs="{required: 'You must accept and agree to the terms and conditions to proceed.'}"></div> */}
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset role="group" aria-labelledby="deliveryPreference">
                <h2 id="deliveryPreference" className="TandCHeaders">Delivery Preference</h2>

                <p>
                  Electronic delivery of your welcome kit and other bank
                  documents will enable you to receive them more quickly and
                  download them for future&nbsp;reference.
                </p>

                <p>
                  <p style={{ color: "#DB0000" }}>
                    <strong>PLEASE NOTE:</strong>
                  </p>
                  <span></span> To gain <u>online</u> access to your HSA, you
                  will need your Optum Bank welcome letter, which will arrive in
                  7-10 business days via USPS mail. This welcome letter will
                  contain the information <u>you need</u> to register for online
                  access of your HSA account.
                </p>

                <ul className="list-files">
                  {!isAarp ? (
                    <li>
                      <a
                        className="file-pdf"
                        target="_blank"
                        rel="noreferrer"
                        href={ElectronicDocument}
                      >
                        Electronic Document Delivery&nbsp;Requirements
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        className="file-pdf"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.optum.com/content/dam/optum/consumer-activation/unknown/HSA_OB_Electronic_Document_Delivery_Requirements.pdf"
                      >
                        Electronic Document Delivery&nbsp;Requirements
                      </a>
                    </li>
                  )}
                </ul>

                {!isAarp && (
                  <div>
                    <p className="delivery-instruction">
                      <span className="cux-icon-asterisk"></span>
                      <span className="hidden">* </span>
                      Please select a document delivery method below.
                    </p>
                  </div>
                )}

                <div className="form-group">
                  <div className="col-sm-12">
                    <div className="checkbox delivery-checkbox">
                      {!account.requestElectronic && isSubmit && (
                        <div className="require-error" id="reqElectronic" role="alert" aria-live="assertive">
                          Delivery method selection is required.
                        </div>
                      )}

                      <label htmlFor="requestElectronic">
                        <input
                          type="checkbox"
                          name="requestElectronic"
                          id="requestElectronic"
                          checked={account.requestElectronic}
                          onChange={handleChange}
                          aria-describedby="reqElectronic"
                        />
                        {!isAarp ? (
                          <div>
                            I would like my welcome kit and other bank documents
                            provided to me electronically and acknowledge that I
                            have read the Electronic Document Delivery
                            Requirements provided above. I will update the email
                            address in my account profile if it changes. I
                            understand that I can withdraw my consent to receive
                            materials electronically at any time by contacting
                            Optum Bank.
                            <div>
                              <br />
                              In the event that you would prefer to have your
                              welcome kit and other bank documents mailed to the
                              address provided in step&nbsp;1 of this
                              enrollment, please{" "}
                             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent default navigation behavior
                                  handleShowElectronic(); // Call your handleShowElectronic function
                              }}
                                id="js-showElectronic"
                              >
                                click&nbsp;here
                              </a>
                              .
                            </div>
                          </div>
                        ) : (
                          <div>
                            I understand that my welcome kit and other bank
                            documents will be provided to me electronically and
                            acknowledge that I have read the Electronic Document
                            Delivery Requirements provided above. I will update
                            the email address in my account profile if it
                            changes.
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                {showElectronic && (
                  <div className="form-group">
                    <div className="col-sm-12">
                      <div className="checkbox">
                        {!account.requestWelcomeKit && isSubmit && (
                          <div className="require-error">You must accept </div>
                        )}

                        <label htmlFor="requestWelcomeKit">
                          <input
                            type="checkbox"
                            name="requestWelcomeKit"
                            id="requestWelcomeKit"
                            checked={account.requestWelcomeKit}
                            onChange={handleChange}
                            // Handle checkbox logic based on your state management
                          />
                          I would like my welcome kit and other bank documents
                          mailed to me. I understand there is no charge to make
                          this change.
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </fieldset>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-5">
              <fieldset className="col-sm-12 " role="group" aria-labelledby="electronicSignature">
                <h2 id="electronicSignature" className="TandCHeaders">Electronic Signature</h2>

                <p>
                  IMPORTANT: We cannot process this application without your
                  electronic signature. Please re-enter your name exactly as you
                  entered it in step&nbsp;1. By entering your name below, you
                  agree that we can rely on your electronic signature for
                  authorization of withdrawals or other transactions on your
                  Account. Once you enter your name below and click the{" "}
                  <em>Next</em> button, your application will be final.
                </p>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="e-sig">
                      <div className="e-sig__firstname">
                        <div className="form-group">
                          <label htmlFor="firstName">
                            First name
                            <span className="cux-icon-asterisk"></span>
                            <span className="hidden"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            id="firstName"
                            value={account.firstName}
                            maxLength={18}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="firstNameReconfirm"
                            autoComplete="given-name"
                          />
                          <div className="help-block required">
                            {accountData?.firstName}
                          </div>
                          {checkNameValidation.firstNameValidation && (
                            <div className="require-error" id="firstNameReconfirm" aria-live="assertive">
                              Must match applicant's First Name.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="e-sig__mi">
                        <div className="form-group">
                          <label htmlFor="middleInitial">Middle initial</label>
                          <input
                            type="text"
                            className="form-control input-1"
                            name="middleInitial"
                            id="middleInitial"
                            value={account.middleInitial}
                            maxLength={1}
                            pattern="[a-zA-Z]"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="middleNameReconfirm"
                            autoComplete="additional-name"
                          />
                          <div className="help-block required">
                            {accountData?.middleInitial}
                          </div>
                          {checkNameValidation.middleNameValidation && (
                            <div className="require-error" id="middleNameReconfirm" aria-live="assertive">
                              Must match applicant's Middle Initial.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="e-sig__lastname">
                        <div className="form-group">
                          <label htmlFor="lastName">
                            Last name<span className="cux-icon-asterisk"></span>
                            <span className="hidden"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastName"
                            value={account.lastName}
                            maxLength={18}
                            pattern="[a-zA-Z\s-]"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="lastNameReconfirm"
                            autoComplete="family-name"
                          />
                          <small className="help-block required">
                            {accountData?.lastName}
                          </small>
                          {checkNameValidation.lastNameValidation && (
                            <div className="require-error" id="lastNameReconfirm" aria-live="assertive">
                              Must match applicant's Last Name.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          {/* Captcha Section */}
          <div className="row">
            <div className="col-sm-12">
              {!isCaptchaLoaded && isReCaptchaEnabled && (
                <div>
                  <Loader
                    wrapperStyle={{ height: "75", width: "75" }}
                    colors={[
                      "#4a173f75",
                      "#4a173f75",
                      "#4a173f75",
                      "#4a173f75",
                      "#4a173f75",
                    ]}
                  />
                </div>
              )}

              {isCaptchaLoaded && isReCaptchaEnabled && (
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={"6LdryC8pAAAAAFP4_zqD3sWgnA6adkqMeEi0jkZg"}
                  onChange={onReCapchaChange}
                  onExpired={handleExpired}
                  key={key}
                />
              )}
            </div>
          </div>

          {/* Form Actions */}
          <div className="form-group">
            <div className="block-actions">
              <button
                type="button"
                id="js-button__previous"
                className="btn btn-default"
                onClick={handleBackClick}
              >
                Previous
              </button>

              { isReCaptchaEnabled ? (
                <>
                <button
                type="button"
                id="js-button__next"
                className="btn btn-primary"
                onClick={handleNextClick}
                disabled={!isCaptchaPass}
              >
                Next
              </button>
              </>
              ) : (
                <>
                <button
                type="button"
                id="js-button__next"
                className="btn btn-primary"
                onClick={handleNextClick}
              >
                Next
              </button>
              </>
              )}
              
              {isAarp && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleCancel}
                  >
                    Cancel Enrollment
                  </button>
                  <EnrollmentModal
                    show={showModal}
                    onClose={handleCloseModal}
                  />
                </>
              )}
            </div>
          </div>
        </form>
      </section>
      </main>
    </>
  );
};

export default TermsConditions;
