  import React,{useState,useEffect} from 'react';
  import Header from "./Header";
  import Footer from "./Footer";
  import { useAarp } from "./hooks/useAarp";

  interface Props {
    isAARP: boolean;
    isMSA: boolean;
    groupNotFound: boolean;
    zeroAcctHolderBalEnd: boolean;
    isOC: boolean;
    showWaivedText: boolean;
    allowInvestments: boolean;
    maintFeeWaived: boolean;
    footnoteFeeDiff: boolean;
    acctHolderMaintFee: string;
    acctHolderBalEnd: string;
    totalMonthlyMaintFee: string;
    showFootnoteTango: boolean;
    showFeeWaived: boolean;
    fulfillmentText: string;
    investmentFeeAmt: string;
    investmentThresholdAmt: string;
    isAarpOrOc: boolean;
    invstAllwInd:boolean;
    invstFeeFlat:boolean;
  }

  const FeeSchedule: React.FC = () => {

  const [feeSchedule, setFeeSchedule] = useState<Props>({
    isAARP: false,
    isMSA: false,
    groupNotFound: false,
    zeroAcctHolderBalEnd: false,
    isOC: false,
    showWaivedText: false,
    allowInvestments: false,
    maintFeeWaived: false,
    footnoteFeeDiff: false,
    acctHolderMaintFee: '',
    acctHolderBalEnd: '',
    totalMonthlyMaintFee: '',
    showFootnoteTango: false,
    showFeeWaived: false,
    fulfillmentText: '',
    investmentFeeAmt: '',
    investmentThresholdAmt: '',
    isAarpOrOc: false,
    invstAllwInd: false,
    invstFeeFlat: false
  });
  const {isAarp} = useAarp();
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement?.hasAttribute('data-serverdata')) {
        const serverData = JSON.parse(rootElement.getAttribute('data-serverdata') || '{}');
        console.log(serverData);
        setFeeSchedule(serverData);
    } else {
        setFeeSchedule(prevFeeSchedule => ({
            ...prevFeeSchedule,
            groupNotFound: true,
            isAARP: isAarp
        }));
    }
}, [isAarp]);

    return (
      <body className="site-enrollment brand-optumbank">
      <Header />
        <div className="container-fluid update">
          <main className="row">
            <div className="col-xs-12">
              <div data-ui-view="" className="view-content">
                <section className="view-feeschedule">
                  <div className="row">
                    <div className="col-lg-7 col-md-9 col-sm-10">
                      <div className="view-masthead">
                        <h1>Schedule of Fees</h1>
                        {feeSchedule?.groupNotFound ? null : (
                          <p>
                            Optum Bank<sup>&reg;</sup>, Member FDIC, wants you to understand the fees associated with your HSA
                            {feeSchedule?.isMSA ? ' or MSA' : ''}. In the chart below, we’ve outlined the fees and how they may apply to your account.
                          </p>
                        )}
                        {feeSchedule?.isAARP && (
                          <p>If you have enabled Optum Wallet functionality (prepaid account or linked external bank account for general spending), please see Optum Wallet Terms and Conditions for fees applicable to those features.</p>
                        )}
                      </div>
                      {feeSchedule?.groupNotFound ? (
                        <p>We're sorry. Your group number could not be found.</p>
                      ) : (
                        <div className="block-group-xl">
                          <h2>Standard Fees</h2>
                          <div className="block-feeschedule">
                            <h3>Monthly Maintenance Fee</h3>
                            {feeSchedule?.zeroAcctHolderBalEnd ? (
                              <p>${feeSchedule?.acctHolderMaintFee} - charged monthly regardless of the average daily balance.</p>
                            ) : (
                              <>
                                {feeSchedule?.isOC ? (
                                  <p>$2.20 (20% discount off standard monthly maintenance fee). Fee waived if average balance is $3,000 or more.*</p>
                                ) : (
                                  <p>${feeSchedule?.acctHolderMaintFee}{feeSchedule?.showWaivedText ? ` – Waived if average balance is $${feeSchedule?.acctHolderBalEnd} or more` : ''}*</p>
                                )}
                              </>
                            )}
                            <p>Includes use of:</p>
                            <ul>
                              <li>Health Savings Account{feeSchedule?.isMSA ? ' or Medicare Advantage Medical Savings Account' : ''} Debit MasterCard<sup>&reg;</sup> &ndash; to pay charges directly</li>
                              <li>Online Bill Payment{feeSchedule?.isMSA ? '' : ' and Mobile Access'}</li>
                              <li>Receipt Vault &ndash; allows you to upload and store images of receipts online</li>
                            </ul>
                            <h3>Other Account Fees</h3>
                            <p>$2.50 per ATM transaction. In addition to our fee, the bank/ATM you use to withdraw funds may charge you their own fee.</p>
                            <p>$20.00 per Outbound Transfer or Rollover to another HSA {feeSchedule?.isMSA ? 'or MSA ' : ''}Custodian.</p>
                            <p>$1.50 printed statement fee. If you do not choose online delivery for your account statements, we may charge this fee for every statement we mail to you. Note: there is no charge for online statement delivery.</p>
                          </div>
                          {feeSchedule?.showFootnoteTango ? (
                        <p className="footnote">
                        {feeSchedule?.showFeeWaived ? (
                              <>
                                  * The standard ${feeSchedule?.totalMonthlyMaintFee} fee is being waived on your account. If fee waiver arrangements change, this ${feeSchedule?.totalMonthlyMaintFee} fee will become your responsibility, and will be assessed to your account{feeSchedule?.maintFeeWaived ? '.' : ` if your average balance is below $${feeSchedule?.acctHolderBalEnd}.`}
                              </>
                          ) : null}
                          The average balance to waive the monthly maintenance fee does not include investment funds.
                        </p>
                      ) : (
                        <p className="footnote">
                          {feeSchedule?.footnoteFeeDiff ? `* $${feeSchedule?.footnoteFeeDiff} of the $${feeSchedule?.totalMonthlyMaintFee} fee is being paid on your behalf. If fee payment arrangements change for your account, the $${feeSchedule?.totalMonthlyMaintFee} fee will become your responsibility and will be assessed to your account${feeSchedule?.acctHolderBalEnd ? ` for average balances below $${feeSchedule?.acctHolderBalEnd}.` : '.'}` : (feeSchedule?.allowInvestments ? '*' : '*')}
                          {feeSchedule?.allowInvestments && 'The average balance to waive the monthly maintenance fee does not include investment funds.'}
                        </p>
                      )}
                        </div>
                      )}
                      {!feeSchedule?.groupNotFound ?  (
                      <div className="block-group-xl" >
                        <h2>Investment Account<sup>†</sup></h2>
                        <div className="block-feeschedule">
                        <div className="fs-investment">
                          <h3>Monthly Investment Fee</h3>
                          {feeSchedule?.allowInvestments ? (
                              feeSchedule?.invstFeeFlat ? (
                                  <p>${feeSchedule?.investmentFeeAmt}</p>
                              ) : (
                                  <p>{feeSchedule?.fulfillmentText}</p>
                              )
                          ) : (
                              <p>Investments are not offered on this account.</p>
                          )}
                        </div>
                          <div className="fs-threshold">
                            <h3>Investment Threshold</h3>
                            {feeSchedule?.allowInvestments ? (
                              <p>${feeSchedule?.investmentThresholdAmt} &ndash; The balance in your {feeSchedule?.isMSA ? 'Cash Account ' : 'HSA '}must remain at or exceed the Investment Threshold each time a new investment is made.</p>
                            ) : (
                              <p>Investments are not offered on this account.</p>
                            )}
                          </div>
                        
                        </div>
                        <p className="footnote"><sup>†</sup> INVESTMENTS ARE NOT FDIC-INSURED, ARE NOT GUARANTEED BY OPTUM BANK, AND MAY LOSE VALUE.</p>
                      </div>
                      ): null}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          <Footer />
          </main>
        <footer />
        </div>
      </body>
    );
  };

  export default FeeSchedule;
