import React, { useEffect } from "react";
import LoaderTemp from "../loader/LoaderTemplate";

const LoaderModal: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    //document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="modal show">
      <div className="modal-dialog" >
        <div className="modal-content loaderModal" aria-modal="true">
            <LoaderTemp
                title="Verifying Information"
                message="Please wait while we process your information"
                />
        </div>
      </div>
    </div>
  );
};

export default LoaderModal;
