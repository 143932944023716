import React from 'react';
import { useEffect, useState } from 'react';
import { ReactComponent as TooltipIcon } from '../components/images/Tooltip_Icon.svg';
import "bootstrap/dist/css/bootstrap.css";

interface OfsInfoTooltipProps {
  text: string;
  tooltipId: string;
}
 
const OfsInfoTooltip: React.FC<OfsInfoTooltipProps> = ({ text, tooltipId }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);

  const showTooltip = () => setIsTooltipVisible(true);
  const hideTooltip = () => setIsTooltipVisible(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideTooltip();
      }
      if(event.key === 'Enter') {
        showTooltip();
      }
    };

    // Add event listener for keydown
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup by removing the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <span className="cux-icon-information hidden-xs hidden-print tooltip-inner" 
    data-toggle="tooltip"  
    data-placement="top" 
    tabIndex={0}
    aria-describedby={tooltipId}
    role="tooltip"
    aria-label='More Information'
    >
    <TooltipIcon className='tooltipIcon' />
    {isTooltipVisible && (
        <div className="tooltip_hover" id={tooltipId} role="tooltip">
          {text}
        </div>
      )}
    </span>
  );
};
 
export default OfsInfoTooltip;